import { createApi } from '@reduxjs/toolkit/query/react';
import {
  BASE_QUERY,
  IDailyStatsCollection,
  IFollowersChart,
  IPeriodChart,
  IPeriodStatistic,
} from '../models';
import {
  TIKTOK_DAILY_STATS,
  TIKTOK_PERIOD_CHART,
  TIKTOK_PERIOD_STATS,
} from '@core/config/const';

export const tiktokDailyStatsAPI = createApi({
  reducerPath: TIKTOK_DAILY_STATS,
  baseQuery: BASE_QUERY,
  endpoints: builder => ({
    getTiktokDailyStats: builder.query<IDailyStatsCollection, any>({
      query: params => ({
        url: TIKTOK_DAILY_STATS,
        params,
      }),
    }),
    getTiktokPeriodStats: builder.query<IPeriodStatistic, any>({
      query: params => ({
        url: TIKTOK_PERIOD_STATS,
        params,
      }),
    }),
    getTiktokPeriodChart: builder.query<IPeriodChart[], any>({
      query: params => ({
        url: TIKTOK_PERIOD_CHART,
        params,
      }),
    }),
    getTiktokFollowersChart: builder.query<IFollowersChart[], any>({
      query: params => ({
        url: `${TIKTOK_PERIOD_CHART}/followers`,
        params,
      }),
    }),
  }),
});

export const {
  useGetTiktokDailyStatsQuery,
  useGetTiktokPeriodStatsQuery,
  useGetTiktokPeriodChartQuery,
  useGetTiktokFollowersChartQuery,
} = tiktokDailyStatsAPI;
