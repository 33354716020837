import { createApi } from '@reduxjs/toolkit/query/react';
import { BASE_QUERY } from '../models';
import { DOWNLOAD } from '@core/config/const';

export const downloadAPI = createApi({
  reducerPath: DOWNLOAD,
  baseQuery: BASE_QUERY,
  endpoints: builder => ({
    download: builder.mutation({
      query(data) {
        return {
          url: `${DOWNLOAD}`,
          method: 'POST',
          body: data,
        };
      },
    }),
  }),
});

export const { useDownloadMutation } = downloadAPI;
