import React, { FC } from 'react';
import { LoginPage } from '@pages/login';

import './styles.scss';

export const AuthLayout: FC = () => {
  return (
    <>
      <div className="auth-layout">
        <LoginPage />
      </div>
    </>
  );
};
