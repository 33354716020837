import { createApi } from '@reduxjs/toolkit/query/react';
import {
  BASE_QUERY,
  IDailyStatsCollection,
  IFollowersChart,
  IPeriodChart,
  IPeriodStatistic,
} from '../models';
import {
  YOUTUBE_DAILY_STATS,
  YOUTUBE_PERIOD_CHART,
  YOUTUBE_PERIOD_STATS,
} from '@core/config/const';

export const youtubeDailyStatsAPI = createApi({
  reducerPath: YOUTUBE_DAILY_STATS,
  baseQuery: BASE_QUERY,
  endpoints: builder => ({
    getYoutubeDailyStats: builder.query<IDailyStatsCollection, any>({
      query: params => ({
        url: YOUTUBE_DAILY_STATS,
        params,
      }),
    }),
    getYoutubePeriodStats: builder.query<IPeriodStatistic, any>({
      query: params => ({
        url: YOUTUBE_PERIOD_STATS,
        params,
      }),
    }),
    getYoutubePeriodChart: builder.query<IPeriodChart[], any>({
      query: params => ({
        url: YOUTUBE_PERIOD_CHART,
        params,
      }),
    }),
    getYoutubeFollowersChart: builder.query<IFollowersChart[], any>({
      query: params => ({
        url: `${YOUTUBE_PERIOD_CHART}/followers`,
        params,
      }),
    }),
  }),
});

export const {
  useGetYoutubeDailyStatsQuery,
  useGetYoutubePeriodStatsQuery,
  useGetYoutubePeriodChartQuery,
  useGetYoutubeFollowersChartQuery,
} = youtubeDailyStatsAPI;
