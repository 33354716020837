import React, { useEffect, useState } from 'react';
import { useGetAllUsersQuery } from '@store/user';
import { USERS_COLLECTION } from '@core/config';
import { useAppSelector } from '@core/hooks';
import { IUser } from '@store/models';
import { ProfileBlock } from '@components/ui-kit/profile-block';
import { guard } from '@core/utils';

import './styles.scss';

const ProfilePage: React.FC = () => {
  const currentUserName = useAppSelector(state => state.user.user);
  const {
    data: usersList = [],
    isSuccess,
    isLoading,
  } = useGetAllUsersQuery(USERS_COLLECTION);
  const [filter, setFilter] = useState(usersList);

  useEffect(() => {
    return setFilter(
      usersList.filter((user: IUser) => {
        return user.userName === currentUserName;
      }),
    );
  }, [currentUserName, usersList, isSuccess]);

  return (
    <div className="profile-page">
      <ProfileBlock usersList={filter} isLoading={isLoading} />
    </div>
  );
};

export const ProfileGuard = guard(ProfilePage);
