import * as React from 'react';
import { Input } from '@progress/kendo-react-inputs';

import './styles.scss';
import { useCreateUserMutation } from '@store/user';

export const AddUser: React.FC = () => {
  const [password, setPassword] = React.useState<string>('');
  const [userName, setUserName] = React.useState<string>('');
  const [success, setSuccess] = React.useState<boolean>(false);

  const usernameValidationMessage: string =
    'Your username should contain only letters!';
  const passwordValidationMessage: string =
    'Please enter password between 6 and 16 characters!';

  const handleChange = (event: any) => {
    if (event.target.name === 'password') {
      setPassword(event.target.value);
    } else if (event.target.name === 'userName') {
      setUserName(event.target.value);
    }
  };

  const [createdUser, { isError }] = useCreateUserMutation();

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    await createdUser({ userName, password });
    setSuccess(true);
    setUserName('');
    setPassword('');

    setTimeout(() => {
      setSuccess(false);
    }, 5000);
  };

  return (
    <div className="add-user">
      <div className="row example-wrapper">
        <div className="col-xs-12 col-sm-6 offset-sm-3 example-col">
          <form className="k-form" onSubmit={handleSubmit}>
            <fieldset>
              <legend>Registration new user</legend>
              <div className="mb-3">
                <Input
                  validityStyles={false}
                  onChange={handleChange}
                  value={userName}
                  name="userName"
                  // style={{ width: '100%' }}
                  label="User Name"
                  pattern={'[A-Za-z]+'}
                  minLength={2}
                  required={true}
                  validationMessage={usernameValidationMessage}
                />
              </div>
              <div className="mb-3">
                <Input
                  validityStyles={false}
                  value={password}
                  onChange={handleChange}
                  name="password"
                  type="password"
                  // style={{ width: '100%' }}
                  label="Password"
                  required={true}
                  minLength={6}
                  maxLength={18}
                  validationMessage={passwordValidationMessage}
                />
              </div>
            </fieldset>
            <input
              style={{ marginTop: '1rem' }}
              type="submit"
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
              value="Add user"
            />
          </form>
        </div>
        {success && (
          <div className="alert alert-success alert-finally">
            {isError ? 'User already exists!' : 'New user added'}
          </div>
        )}
      </div>
    </div>
  );
};
