import React, { useEffect, useState } from 'react';
import numbro from 'numbro';
import { useMatch } from 'react-router-dom';
import { IVideoPost } from '@store/models';

export const TotalSharesCell: React.FC<any> = ({ ...props }) => {
  let styles = props.style;
  let classNames = props.className;
  const [totalShares, setTotalShares] = useState();
  const postId = props.dataItem.postId;
  const PostData: IVideoPost = props.PostsData.find(
    (post: IVideoPost) => post._id === postId,
  );
  const ReelData: IVideoPost = props.ReelsData.find((reel: IVideoPost) => {
    return reel._id === postId;
  });

  const isTiktok = useMatch('/tiktok');
  const isInstagram = useMatch('/instagram');

  useEffect(() => {
    if (isTiktok) {
      setTotalShares(PostData?.video.stats.shareCount);
    }
  }, [PostData, isTiktok]);

  return (
    <td style={{ textAlign: 'center', ...styles }} className={classNames}>
      {numbro(totalShares).format({ thousandSeparated: true, mantissa: 0 })}
    </td>
  );
};
