import { createApi } from '@reduxjs/toolkit/query/react';
import { BASE_QUERY, IParserHistoryObject } from '../models';
import { PARSERHISTORY_COLLECTION } from '@core/config/const';

export const parserHistoryAPI = createApi({
  reducerPath: PARSERHISTORY_COLLECTION,
  tagTypes: ['ParserHistory'],
  baseQuery: BASE_QUERY,
  endpoints: builder => ({
    getParserHistory: builder.query<[Array<IParserHistoryObject>, number], any>({
      query: params => ({
          url: PARSERHISTORY_COLLECTION,
          method: 'GET',
          params,
        }),
      providesTags: ['ParserHistory'],
    }),
  }),
});

export const {
  useGetParserHistoryQuery,
} = parserHistoryAPI;
