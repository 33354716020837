import React, { useEffect, useState } from 'react';
import { CHANNEL_NAME, TIKTOK_LINK } from '@core/config';
import { useGetTiktokPostQuery } from '@store/tiktok';
import { useMatch } from 'react-router-dom';
import { IVideoPost } from '@store/models';

export const ImgRowCell: React.FC<any> = ({ ...props }) => {
  let styles = props.style;
  let classNames = props.className;

  const postId: string = props.dataItem._id;
  const isTiktok = useMatch('/tiktok/comments-tree');
  const isInstagram = useMatch('/instagram/comments-tree');
  const isYoutube = useMatch('/instagram/comments-tree');

  const { data: PostData } = useGetTiktokPostQuery(postId);
  const [postData, setPostData] = useState<IVideoPost>(PostData);

  useEffect(() => {
    setPostData(PostData);
  }, [PostData]);

  const srtVideo = async () => {
    props.onClick();
    if (isTiktok) {
      props.setCurrentLink(`${TIKTOK_LINK}@${CHANNEL_NAME}/video/${postId}`);
    }
  };

  let imageElement;

  if (isTiktok || isYoutube) {
    imageElement = (
      <img
        onClick={srtVideo}
        src={postData?.video.video.cover}
        alt={postData?.video.desc}
      />
    );
  }
  if (isInstagram) {
    imageElement = (
      <img
        onClick={srtVideo}
        src="https://www.siasat.com/wp-content/uploads/2021/11/Instagram-2.jpg"
        alt="img"
      />
    );
  }

  return (
    <td style={{ textAlign: 'center', ...styles }} className={classNames}>
      <div className="comments-table__img-wrapper">{imageElement}</div>
    </td>
  );
};
