import { guard } from '@core/utils';
import './styles.scss';
import React from 'react';
import { HistoryList } from '@components/ui-kit/history-list';

const ParserHistoryPage: React.FC<any> = () => {
  return (
    <div className="parser-history-page">
      <HistoryList />
    </div>
  );
};

export const ParserHistoryGuard = guard(ParserHistoryPage);
