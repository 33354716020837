import React, { useState } from 'react';
import { UserUpdateModal } from '../user-update-modal';
import { useEffect } from 'react';
import { Spinner } from '../spinner';
import { IUser } from '@store/models';
import { UserDeleteModal } from '../user-delete-modal';

type PropsType = {
  usersList: IUser[];
  isLoading: boolean;
};

export const ProfileBlock: React.FC<PropsType> = ({ usersList, isLoading }) => {
  const [currentUser, SetCurrentUser] = useState(usersList[0]);
  const [visibleEditModal, setVisibleEditModal] =
    React.useState<boolean>(false);

  useEffect(() => {
    SetCurrentUser(usersList[0]);
  }, [usersList]);

  const handleCloseEditModal = () => {
    setVisibleEditModal(!visibleEditModal);
  };

  const [visibleDeleteModal, setVisibleDeleteModal] =
    React.useState<boolean>(false);

  const handleDeleteEditModal = () => {
    setVisibleDeleteModal(!visibleDeleteModal);
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <UserUpdateModal
            currentUser={currentUser}
            visible={visibleEditModal}
            handleClose={handleCloseEditModal}
          />
          <UserDeleteModal
            currentUser={currentUser}
            visible={visibleDeleteModal}
            handleClose={handleDeleteEditModal}
          />
          <div className="user-list">
            <div className="k-listview-item row p-2 border-bottom align-middle user-list__content">
              <div className="col-6">
                <h2
                  style={{
                    color: '#454545',
                  }}
                  className="text-uppercase"
                >
                  {currentUser?.userName}
                </h2>
                <div
                  style={{
                    fontSize: 14,
                    color: '#a0a0a0',
                  }}
                >
                  {currentUser?.userRole}
                </div>
              </div>

              <div className="col-4 user-list__content__btn-block">
                <div className="k-chip k-chip-md k-rounded-md k-chip-solid k-chip-solid-base">
                  <div
                    onClick={() => handleCloseEditModal()}
                    className="k-chip-content"
                  >
                    Edit
                  </div>
                </div>
                <div className="k-chip k-chip-md k-rounded-md k-chip-solid k-chip-solid-base">
                  <div
                    onClick={() => handleDeleteEditModal()}
                    className="k-chip-content"
                  >
                    Delete
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
