import React, { useState, useEffect } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { IUser, IUserRole } from '@store/models';
import { useRemoveUserMutation } from '@store/user';
import { useAppSelector } from '@core/hooks';
import { useNavigate } from 'react-router-dom';

type PropsType = {
  currentUser: IUser;
  visible: boolean;
  handleClose: () => void;
};

export const UserDeleteModal: React.FC<PropsType> = ({
  currentUser,
  visible,
  handleClose,
}) => {
  const [userName, setUserName] = React.useState<string>(currentUser?.userName);
  const [removedUser] = useRemoveUserMutation();
  const currentUserRole = useAppSelector(state => state?.user.userRole);
  const [isCurrentAdmin, setIsCurrentIsAdmin] = useState(false);
  const redirect = useNavigate();

  useEffect(() => {
    if (currentUserRole === IUserRole.admin) {
      setIsCurrentIsAdmin(true);
    } else {
      setIsCurrentIsAdmin(false);
    }
  }, [currentUserRole]);

  const removeUser = async () => {
    await removedUser({ id: currentUser?._id });
    handleClose();
    if (!isCurrentAdmin) {
      redirect('/auth');
    }
  };

  useEffect(() => {
    setUserName(currentUser?.userName);
  }, [currentUser]);

  return (
    <div>
      {visible && (
        <Dialog height={250} width={450} onClose={handleClose}>
          <p style={{ margin: '25px', textAlign: 'center' }}>
            Deleting {userName} user
          </p>
          <DialogActionsBar>
            <button
              onClick={removeUser}
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
            >
              Confirm
            </button>
            <button
              onClick={handleClose}
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
            >
              Cancel
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
    </div>
  );
};
