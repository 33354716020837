import React, { useEffect, useState } from 'react';
import { useMatch, useOutletContext } from 'react-router-dom';
import { IVideoDailyStats, IVideoPost } from '@store/models';
import {
  useGetTiktokDailyStatsQuery,
  useGetTiktokPostsArrByIdQuery,
} from '@store/tiktok';
import {
  useGetInstagramDailyStatsQuery,
  useGetInstagramReelsArrByIdQuery,
} from '@store/instagram';
import {
  useGetYoutubeDailyStatsQuery,
  useGetYoutubeVideosArrByIdQuery,
} from '@store/youtube';

import { Popup } from '@progress/kendo-react-popup';
import { SortDescriptor } from '@progress/kendo-data-query';
import {
  InstagramEmbed,
  TikTokEmbed,
  YouTubeEmbed,
} from 'react-social-media-embed';
import { Spinner } from '@components/ui-kit';
import {
  TagsCell,
  ImgCell,
  TitleCell,
  TotalCommentsCell,
  CommentsCell,
} from './models';

import {
  Grid,
  GridColumn as Column,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid';
import { IDailyStats } from '@store/models/daily-stats.interface';
import { EPlatform } from '@models/platform.enum';
import { formatData } from './format-data';

import './styles.scss';

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = {
  take: 10,
  skip: 0,
};

const initialSort: Array<SortDescriptor> = [
  { field: 'followers', dir: 'desc' },
];

const PopUp: React.FC<any> = ({ show, anchor, currentLink }) => {
  const isTiktok = useMatch('/tiktok');
  const isInstagram = useMatch('/instagram');
  const isYoutube = useMatch('/youtube');

  return (
    <Popup show={show} anchor={anchor.current}>
      {isTiktok && <TikTokEmbed url={currentLink} />}
      {isInstagram && <InstagramEmbed url={currentLink} />}
      {isYoutube && <YouTubeEmbed url={currentLink} />}
    </Popup>
  );
};

type PropsType = {
  startDate: string;
  endDate: string;
  searchParams: any;
  setSearchParams: any;
  post_id: any;
  setPost_id: any;
};

export const InformTable: React.FC = () => {
  const {
    startDate,
    endDate,
    searchParams,
    post_id,
    setPost_id,
    setSearchParams,
  } = useOutletContext() as PropsType;

  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [countCollection, setCountCollection] = useState<number>(0);
  const [DailyStatsData, setDailyStatsData] = useState<IVideoDailyStats[]>([]);
  const [dailyStats, setDailyStats] = useState<IDailyStats[]>([]);
  const [DailyStatsLoading, setDailyStatsLoading] = useState<boolean>(true);
  const [PostsData, setPostsData] = useState<IVideoPost[]>([]);
  const [VideosData, setVideosData] = useState<IVideoPost[]>([]);
  const [ReelsData, setReelsData] = useState<IVideoPost[]>([]);
  const [postsIdArr, setPostsIdArr] = useState<string>();
  const [videosIdArr, setVideosIdArr] = useState<string>();
  const [reelsIdArr, setReelsIdArr] = useState<string>();
  const [sort, setSort] = useState(initialSort);
  const [sortParam, setSortParam] = useState<string>('followers');
  const [sortOrderParam, setSorOrderParam] = useState<any>('');

  const [show, setShow] = React.useState(false);
  const [currentLink, setCurrentLink] = useState<string>('');
  const anchor = React.useRef(null);

  const pageQuery = searchParams.get('page') || currentPage;
  const isTiktok = useMatch('/tiktok');
  const isInstagram = useMatch('/instagram');
  const isYoutube = useMatch('/youtube');

  useEffect(() => {
    if (sort) {
      setSortParam(sort[0]?.field || 'followers');
      setSorOrderParam(sort[0]?.dir || 'desc');
    }
  }, [sort]);

  const { data: DailyStatsDataTiktok, isLoading: DailyStatsLoadingTiktok } =
    useGetTiktokDailyStatsQuery(
      startDate &&
        endDate &&
        sort && {
          start: startDate,
          end: endDate,
          sort: sortParam,
          ord: sortOrderParam,
          page: pageQuery,
          post_id: post_id,
        },
    );
  const {
    data: DailyStatsDataInstagram,
    isLoading: DailyStatsLoadingInstagram,
  } = useGetInstagramDailyStatsQuery(
    startDate &&
      endDate &&
      sort && {
        start: startDate,
        end: endDate,
        sort: sortParam,
        ord: sortOrderParam,
        page: pageQuery,
        post_id: post_id,
      },
  );

  const { data: DailyStatsDataYoutube, isLoading: DailyStatsLoadingYoutube } =
    useGetYoutubeDailyStatsQuery(
      startDate &&
        endDate &&
        sort && {
          start: startDate,
          end: endDate,
          sort: sortParam,
          ord: sortOrderParam,
          page: pageQuery,
          post_id: post_id,
        },
    );

  const { data: postsArr = [], isLoading: tiktokPostLoading } =
    useGetTiktokPostsArrByIdQuery(postsIdArr || null);

  const { data: reelsArr = [], isLoading: instagramReelsLoading } =
    useGetInstagramReelsArrByIdQuery(reelsIdArr || null);

  const { data: videosArr = [], isLoading: youtubeVideosLoading } =
    useGetYoutubeVideosArrByIdQuery(videosIdArr || null);

  useEffect(() => {
    const newSearchParams = new URLSearchParams({
      start: startDate,
      end: endDate,
      sort: sortParam,
      ord: sortOrderParam,
      page: `${currentPage}`,
      post_id: post_id,
    });
    setSearchParams(newSearchParams);
  }, [
    startDate,
    endDate,
    sort,
    currentPage,
    setSearchParams,
    sortParam,
    sortOrderParam,
    post_id,
  ]);

  useEffect(() => {
    if (isTiktok && DailyStatsDataTiktok && !DailyStatsLoadingTiktok) {
      setDailyStatsData(DailyStatsDataTiktok.collection);
      setCountCollection(DailyStatsDataTiktok.countCollection);
      setPostsData(postsArr);
      setDailyStats(
        formatData({
          platform: EPlatform.TIKTOK,
          posts: postsArr,
          postsData: DailyStatsDataTiktok.collection,
        }),
      );
    }
    if (isInstagram && DailyStatsDataInstagram && !DailyStatsLoadingInstagram) {
      setDailyStatsData(DailyStatsDataInstagram.collection);
      setCountCollection(DailyStatsDataInstagram.countCollection);
      setReelsData(reelsArr);
      setDailyStats(
        formatData({
          platform: EPlatform.INSTAGRAM,
          posts: reelsArr,
          postsData: DailyStatsDataInstagram.collection,
        }),
      );
    }
    if (isYoutube && DailyStatsDataYoutube && !DailyStatsLoadingYoutube) {
      setDailyStatsData(DailyStatsDataYoutube.collection);
      setCountCollection(DailyStatsDataYoutube.countCollection);
      setVideosData(videosArr);
      setDailyStats(
        formatData({
          platform: EPlatform.YOUTUBE,
          posts: videosArr,
          postsData: DailyStatsDataYoutube.collection,
        }),
      );
    }
  }, [
    DailyStatsDataInstagram,
    DailyStatsDataTiktok,
    DailyStatsDataYoutube,
    DailyStatsLoadingInstagram,
    DailyStatsLoadingTiktok,
    DailyStatsLoadingYoutube,
    isInstagram,
    isTiktok,
    isYoutube,
    postsArr,
    reelsArr,
    videosArr,
  ]);

  useEffect(() => {
    if (DailyStatsDataTiktok?.collection && !DailyStatsLoadingTiktok) {
      const filteredCollection: string[] = [];
      DailyStatsData.map((item: IVideoDailyStats) => {
        return filteredCollection.push(item.postId);
      });
      setPostsIdArr(filteredCollection.toString());
    }
  }, [
    DailyStatsData,
    DailyStatsDataTiktok,
    DailyStatsLoading,
    DailyStatsLoadingTiktok,
  ]);

  useEffect(() => {
    if (DailyStatsDataInstagram?.collection && !DailyStatsLoadingInstagram) {
      const filteredCollection: string[] = [];
      DailyStatsDataInstagram.collection.map((item: IVideoDailyStats) => {
        return filteredCollection.push(item.postId);
      });
      setReelsIdArr(filteredCollection.toString());
    }
  }, [
    DailyStatsData,
    DailyStatsDataInstagram,
    DailyStatsLoading,
    DailyStatsLoadingInstagram,
  ]);

  useEffect(() => {
    if (DailyStatsDataYoutube?.collection && !DailyStatsLoadingYoutube) {
      const filteredCollection: string[] = [];
      DailyStatsDataYoutube.collection.map((item: IVideoDailyStats) => {
        return filteredCollection.push(item.postId);
      });
      setVideosIdArr(filteredCollection.toString());
    }
  }, [
    DailyStatsData,
    DailyStatsDataYoutube,
    DailyStatsLoading,
    DailyStatsLoadingYoutube,
  ]);

  useEffect(() => {
    if (isTiktok && !DailyStatsLoadingTiktok && !tiktokPostLoading) {
      setDailyStatsLoading(false);
    }
    if (isInstagram && !DailyStatsLoadingInstagram && !instagramReelsLoading) {
      setDailyStatsLoading(false);
    }
    if (isYoutube && !DailyStatsLoadingYoutube && !youtubeVideosLoading) {
      setDailyStatsLoading(false);
    }
  }, [
    DailyStatsLoadingInstagram,
    DailyStatsLoadingTiktok,
    DailyStatsLoadingYoutube,
    instagramReelsLoading,
    youtubeVideosLoading,
    isInstagram,
    isTiktok,
    isYoutube,
    tiktokPostLoading,
  ]);

  const PopupHandler = () => {
    setShow(!show);
  };

  const ImgCellProps = (props: any) => (
    <ImgCell
      {...{
        ...props,
        currentLink: currentLink,
        setCurrentLink: setCurrentLink,
        onClick: PopupHandler,
      }}
    />
  );

  const TagsCellProps = (props: any) => (
    <TagsCell
      {...{
        ...props,
        PostsData: PostsData,
        ReelsData: ReelsData,
        VideosData: VideosData,
      }}
    />
  );

  const CommentsCellProps = (props: any) => (
    <CommentsCell
      {...{
        ...props,
        PostsData: PostsData,
        ReelsData: ReelsData,
        VideosData: VideosData,
        setPost_id: setPost_id,
      }}
    />
  );

  const TotalCommentsCellProps = (props: any) => (
    <TotalCommentsCell
      {...{
        ...props,
        PostsData: PostsData,
        VideosData: VideosData,
        ReelsData: ReelsData,
        setPost_id: setPost_id,
      }}
    />
  );

  const pageChange = (event: GridPageChangeEvent) => {
    setPage({
      ...event.page,
    });
  };

  useEffect(() => {
    setCurrentPage(Number(page.skip) / 10 + 1);
  }, [page]);

  return (
    <>
      {DailyStatsLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="anchor" ref={anchor}></div>
          <div className="inform-table">
            <PopUp
              show={show}
              PopupHandler={PopupHandler}
              anchor={anchor}
              currentLink={currentLink}
            />
            <Grid
              pageable={true}
              sortable={true}
              lockGroups={true}
              filterable={false}
              data={dailyStats}
              skip={page.skip}
              take={page.take}
              onPageChange={pageChange}
              total={countCollection}
              sort={sort}
              onSortChange={(e: GridSortChangeEvent) => {
                setSort(e.sort);
              }}
            >
              <Column
                title="Image"
                width="80px"
                locked={true}
                cell={ImgCellProps}
              />
              <Column
                title="Title"
                width="350px"
                locked={true}
                cell={TitleCell}
              />
              <Column field="er" title="Er" width="80px" />
              <Column field="forecast" title="Forecast" width="120px" />
              <Column title="Tags" width="160px" cell={TagsCellProps} />
              <Column field="sec" title="sec" width="80px" />
              <Column field="fin" title="Fin" width="80px" />
              <Column field="date" title="Date" width="120px" />
              <Column field="followers" title="Followers" width="100px" />
              <Column field="views" title="Views" width="80px" />
              <Column field="likes" title="Likes" width="80px" />
              <Column
                field="comments"
                title="Сomments"
                width="100px"
                cell={CommentsCellProps}
              />
              <Column field="shares" title="Shares" width="80px" />
              <Column field="totalViews" title="∑Views" width="100px" />
              <Column field="totalLikes" title="∑Likes" width="100px" />
              <Column
                field="totalComments"
                title="∑Comments"
                width="100px"
                cell={TotalCommentsCellProps}
              />
              <Column field="totalShares" title="∑Shares" width="80px" />
              <Column field="totalHours" title="∑Hours" width="120px" />
            </Grid>
          </div>
        </>
      )}
    </>
  );
};
