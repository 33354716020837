import { createApi } from '@reduxjs/toolkit/query/react';
import { BASE_QUERY } from '../models';
import { AUTH } from '@core/config/const';

export const authAPI = createApi({
  reducerPath: AUTH,
  baseQuery: BASE_QUERY,
  endpoints: builder => ({
    loginUser: builder.mutation({
      query(data) {
        return {
          url: `${AUTH}/login`,
          method: 'POST',
          body: data,
        };
      },
    }),
  }),
});

export const { useLoginUserMutation } = authAPI;

// if (authAPI) {
//   localStorage.setItem('user', JSON.stringify(true));
// }else{
//   localStorage.setItem('user', JSON.stringify(false));
// }
