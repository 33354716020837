import React, { useEffect, useState } from 'react';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { DatePicker, PeriodPicker, Spinner } from '@components/ui-kit';
import { useMatch } from 'react-router-dom';
import { useGetTiktokPeriodStatsQuery } from '@store/tiktok';
import { useGetInstagramPeriodStatsQuery } from '@store/instagram';
import { useGetYoutubePeriodStatsQuery } from '@store/youtube';
import { IPeriodStatistic } from '@store/models';

import './styles.scss';

type PropsType = {
  setSearchParams: any;
  startDate: string;
  setStartDate: any;
  endDate: string;
  setEndDate: any;
};

export const HomeHeaderBlock: React.FC<PropsType> = ({
  setSearchParams,
  startDate,
  setEndDate,
  setStartDate,
  endDate,
}) => {
  const [PeriodStats, setPeriodStats] = useState<IPeriodStatistic>();
  const [PeriodStatsLoading, setPeriodStatsLoading] = useState(false);

  const isTiktok = useMatch('/tiktok');
  const isInstagram = useMatch('/instagram');
  const isYoutube = useMatch('/youtube');
  const isAllPlatform = useMatch('/all');

  const { data: TiktokPeriodStats, isLoading: tiktokLoadingPeriodStats } =
    useGetTiktokPeriodStatsQuery(
      startDate && endDate && { start: startDate, end: endDate },
    );
  const { data: InstagramPeriodStats, isLoading: instagramLoadingPeriodStats } =
    useGetInstagramPeriodStatsQuery(
      startDate && endDate && { start: startDate, end: endDate },
    );

  const { data: YoutubePeriodStats, isLoading: youtubeLoadingPeriodStats } =
    useGetYoutubePeriodStatsQuery(
      startDate && endDate && { start: startDate, end: endDate },
    );

  useEffect(() => {
    if (isTiktok && TiktokPeriodStats && !tiktokLoadingPeriodStats) {
      setPeriodStats(TiktokPeriodStats);
      setPeriodStatsLoading(tiktokLoadingPeriodStats);
    }
    if (isInstagram && InstagramPeriodStats && !instagramLoadingPeriodStats) {
      setPeriodStats(InstagramPeriodStats);
      setPeriodStatsLoading(instagramLoadingPeriodStats);
    }
    if (isYoutube && YoutubePeriodStats && !youtubeLoadingPeriodStats) {
      setPeriodStats(YoutubePeriodStats);
      setPeriodStatsLoading(youtubeLoadingPeriodStats);
    }
    if (isAllPlatform) {
      const allStats = {
        views: 0,
        comments: 0,
        followers: 0,
        likes: 0,
        shares: 0,
        er: 0,
        erCount: 0,
      };
      const addStats = (stats: IPeriodStatistic) => {
        if (stats) {
          allStats.views += !isNaN(parseInt(stats.views.replace(/,/g, ''), 10))
            ? parseInt(stats.views.replace(/,/g, ''), 10)
            : 0;
          allStats.comments += !isNaN(
            parseInt(stats.comments.replace(/,/g, ''), 10),
          )
            ? parseInt(stats.comments.replace(/,/g, ''), 10)
            : 0;
          allStats.followers += !isNaN(
            parseInt(stats.followers.replace(/,/g, ''), 10),
          )
            ? parseInt(stats.followers.replace(/,/g, ''), 10)
            : 0;
          allStats.likes += !isNaN(parseInt(stats.likes.replace(/,/g, ''), 10))
            ? parseInt(stats.likes.replace(/,/g, ''), 10)
            : 0;
          allStats.shares += !isNaN(
            parseInt(stats.shares.replace(/,/g, ''), 10),
          )
            ? parseInt(stats.shares.replace(/,/g, ''), 10)
            : 0;
          if (!isNaN(parseFloat(stats.er))) {
            allStats.er += parseFloat(stats.er);
            allStats.erCount += 1;
          }
        }
      };
      if (TiktokPeriodStats) addStats(TiktokPeriodStats);
      if (InstagramPeriodStats) addStats(InstagramPeriodStats);
      if (YoutubePeriodStats) addStats(YoutubePeriodStats);

      const formattedStats: IPeriodStatistic = {
        views: allStats.views.toLocaleString(),
        comments: allStats.comments.toLocaleString(),
        followers: allStats.followers.toLocaleString(),
        likes: allStats.likes.toLocaleString(),
        shares: allStats.shares.toLocaleString(),
        er:
          allStats.erCount > 0
            ? (allStats.er / allStats.erCount).toFixed(2)
            : '0',
      };

      setPeriodStats(formattedStats);
      setPeriodStatsLoading(
        tiktokLoadingPeriodStats ||
          instagramLoadingPeriodStats ||
          youtubeLoadingPeriodStats,
      );
    }
  }, [
    InstagramPeriodStats,
    TiktokPeriodStats,
    YoutubePeriodStats,
    instagramLoadingPeriodStats,
    youtubeLoadingPeriodStats,
    tiktokLoadingPeriodStats,
    isInstagram,
    isTiktok,
    isYoutube,
    isAllPlatform,
  ]);

  const viewsTitle = 'Views for the period';
  const commentsTitle = 'Comments for the period';
  const followersTitle = 'Followers for the period';
  const likesTitle = 'Likes for the period';
  const sharesTitle = 'Shares for the period';
  const engagementTitle = 'Engagement Rate for the period';

  return (
    <div className="home-header-block">
      {PeriodStatsLoading ? (
        <Spinner />
      ) : (
        <Tooltip>
          <div className="statistic-block">
            <p className="statistic-block__views" title={viewsTitle}>
              Views: <span title={viewsTitle}>{PeriodStats?.views}</span>
            </p>
            <p className="statistic-block__comments" title={commentsTitle}>
              Comments:{' '}
              <span title={commentsTitle}>{PeriodStats?.comments}</span>
            </p>
            <p className="statistic-block__followers" title={followersTitle}>
              Followers:{' '}
              <span title={followersTitle}>{PeriodStats?.followers}</span>
            </p>
            <p className="statistic-block__likes" title={likesTitle}>
              Likes: <span title={likesTitle}>{PeriodStats?.likes}</span>
            </p>
            <p className="statistic-block__shares" title={sharesTitle}>
              Shares: <span title={sharesTitle}>{PeriodStats?.shares}</span>
            </p>
            <p className="statistic-block__er" title={engagementTitle}>
              ER: <span title={engagementTitle}>{PeriodStats?.er}%</span>
            </p>
          </div>
        </Tooltip>
      )}
      <div className="data-picker-block">
        <PeriodPicker
          setSearchParams={setSearchParams}
          startDate={startDate}
          endDate={endDate}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
        />
        <DatePicker
          setSearchParams={setSearchParams}
          startDate={startDate}
          endDate={endDate}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
        />
      </div>
    </div>
  );
};
