import { ObjectId } from 'mongodb';

export interface IUser {
  _id: ObjectId;
  userName: string;
  password: string;
  userRole: IUserRole;
}

export interface IUserLogin {
  userName: string;
  password: string;
}

export enum IUserRole {
  admin = 'admin',
  user = 'user',
}
