import React from 'react';
import { useConnectToYoutubeQuery } from '@store/youtube';
import {
  INSTAGRAM_CONNECT,
  TIKTOK_CONNECT,
  YOUTUBE_CONNECT,
} from '@core/config';
import { useConnectToInstagramQuery } from '@store/instagram';
import { useConnectToTiktokQuery } from '@store/tiktok';
// import './styles.scss';
import { guard } from '@core/utils';

const ConnectPage: React.FC = () => {
  const { data: youtubeLink } = useConnectToYoutubeQuery(YOUTUBE_CONNECT);
  const { data: instagramLink } = useConnectToInstagramQuery(INSTAGRAM_CONNECT);
  const { data: tiktokLink } = useConnectToTiktokQuery(TIKTOK_CONNECT);

  const connectToYouTube = async () => {
    window.open(youtubeLink.link_to_authorize);
  };

  const connectToInstagram = async () => {
    window.open(instagramLink.link_to_authorize);
  };

  const connectToTiktok = async () => {
    window.open(tiktokLink.link_to_authorize);
  };

  return (
    <div className="social-connect-page">
      <h1>Connect to Social Media</h1>
      <div className="button-container">
        <button className="connect-button" onClick={connectToTiktok}>
          Connect to Tiktok
        </button>
        <button className="connect-button" onClick={connectToInstagram}>
          Connect to Instagram
        </button>
        <button className="connect-button" onClick={connectToYouTube}>
          Connect to YouTube
        </button>
      </div>
    </div>
  );
};

export const ConnectGuard = guard(ConnectPage);
