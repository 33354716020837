import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import { AuthLayout, MainLayout } from './app/layouts';
import {
  CommentsPageGuard,
  ErrorGuard,
  HomeGuard,
  ProfileGuard,
  UsersGuard,
} from '@pages/index';
import { CommentsTree, Downloads, InformTable } from '@components/ui-kit';
import { ParserHistoryGuard } from '@pages/parser-history';
import { CommentsTable } from '@components/ui-kit/comments-table/comments-table';
import { ConnectGuard } from '@pages/connect';

const props = {
  canActivate: (user: string) => !!user,
  redirect: '/auth',
};

export const router = createBrowserRouter([
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '', element: <Navigate to="/tiktok" replace /> },
      { path: 'users', element: <UsersGuard {...props} /> },
      { path: 'profile', element: <ProfileGuard {...props} /> },
      {
        path: 'all',
        element: <HomeGuard {...props} />,
        children: [
          {
            path: '',
            element: <InformTable />,
          },
          { path: 'comments-tree', element: <CommentsTree /> },
        ],
      },
      {
        path: 'tiktok',
        element: <HomeGuard {...props} />,
        children: [
          {
            path: '',
            element: <InformTable />,
          },
          { path: 'comments-tree', element: <CommentsTree /> },
        ],
      },
      {
        path: 'instagram',
        element: <HomeGuard {...props} />,
        children: [
          {
            path: '',
            element: <InformTable />,
          },
          { path: 'comments-tree', element: <CommentsTree /> },
        ],
      },
      {
        path: 'youtube',
        element: <HomeGuard {...props} />,
        children: [
          {
            path: '',
            element: <InformTable />,
          },
          { path: 'comments-tree', element: <CommentsTree /> },
        ],
      },
      { path: 'parserHistory', element: <ParserHistoryGuard {...props} /> },
      { path: 'connectTo', element: <ConnectGuard {...props} /> },
      {
        path: 'comments',
        element: <CommentsPageGuard {...props} />,
        children: [
          { path: 'tiktok', element: <CommentsTable /> },
          { path: 'instagram', element: <CommentsTable /> },
          { path: 'youtube', element: <CommentsTable /> },
        ],
      },
      { path: 'downloads', element: <Downloads /> },
      {
        path: 'error',
        children: [
          {
            index: true,
            element: <ErrorGuard {...props} />,
          },
        ],
      },
    ],
  },
  {
    path: '/auth',
    element: <AuthLayout />,
    children: [],
  },
]);
