import React, { useState } from 'react';
import { InsertNavigation } from '@components/ui-kit';
import { ChartsBlock, HomeHeaderBlock } from './models';
import { guard } from '@core/utils';
import { Outlet, useSearchParams } from 'react-router-dom';

import './styles.scss';

const HomePage: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [post_id, setPost_id] = React.useState<string>('');


  return (
    <div className="home">
      <HomeHeaderBlock
        setSearchParams={setSearchParams}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
      <ChartsBlock startDate={startDate} endDate={endDate} />
      <InsertNavigation />
      <Outlet
        context={{
          startDate,
          endDate,
          searchParams,
          post_id,
          setPost_id,
          setSearchParams,
        }}
      />
    </div>
  );
};

export const HomeGuard = guard(HomePage);
