import React, { useEffect, useState } from 'react';
import {
  Grid,
  GridColumn as Column,
  GridPageChangeEvent,
} from '@progress/kendo-react-grid';
import { useMatch } from 'react-router-dom';
import { useGetInstagramCommentsQuery } from '@store/instagram';
import { useGetTiktokCommentsQuery } from '@store/tiktok';
import { useGetYoutubeCommentsQuery } from '@store/youtube';
import { Spinner } from '../spinner';
import CommentCell from '../comments-tree/models/comment-cell/comment-cell';
import { ImgCommentsCell } from './models/img-row-cell';
import { DataResult } from '@progress/kendo-data-query';

const pageSize: number = 100;

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = {
  take: pageSize,
  skip: 0,
};
const ImgCell = (props: any) => (
  <ImgCommentsCell
    {...{
      ...props,
    }}
  />
);

export const CommentsTable: React.FC = () => {
  const isTiktok = useMatch('/comments/tiktok');
  const isInstagram = useMatch('/comments/instagram');
  const isYoutube = useMatch('/comments/youtube');
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [isLoading, setisLoading] = useState<boolean>(true);

  const { data: tiktokcommentArr = [], isLoading: tiktokCommentsLoading } =
    useGetTiktokCommentsQuery(page);

  const {
    data: instagramcommentArr = [],
    isLoading: instagramCommentsLoading,
  } = useGetInstagramCommentsQuery(page);

  const { data: youtubecommentArr = [], isLoading: youtubeCommentsLoading } =
    useGetYoutubeCommentsQuery(page);

  const [dataGrid, setDataGrid] = React.useState<DataResult>({
    data: [],
    total: 0,
  });

  useEffect(() => {
    if (isTiktok) {
      setDataGrid({
        data: tiktokcommentArr[0] ? tiktokcommentArr[0] : [],
        total: tiktokcommentArr[1] ? tiktokcommentArr[1] : 0,
      });
    } else if (isInstagram) {
      setDataGrid({
        data: instagramcommentArr[0] ? instagramcommentArr[0] : [],
        total: instagramcommentArr[1] ? instagramcommentArr[1] : 0,
      });
    } else if (isYoutube) {
      setDataGrid({
        data: youtubecommentArr[0] ? youtubecommentArr[0] : [],
        total: youtubecommentArr[1] ? youtubecommentArr[1] : 0,
      });
    }
  }, [
    instagramcommentArr,
    isInstagram,
    isTiktok,
    isYoutube,
    tiktokcommentArr,
    youtubecommentArr,
  ]);
  useEffect(() => {
    if (isTiktok && !tiktokCommentsLoading) {
      setisLoading(false);
    }
    if (isInstagram && !instagramCommentsLoading) {
      setisLoading(false);
    }
    if (isYoutube && !youtubeCommentsLoading) {
      setisLoading(false);
    }
  }, [
    tiktokCommentsLoading,
    instagramCommentsLoading,
    youtubeCommentsLoading,
    isInstagram,
    isTiktok,
    isYoutube,
  ]);
  const pageChange = (event: GridPageChangeEvent) => {
    setPage({
      ...event.page,
    });
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="history-list">
          <Grid
            pageable={true}
            sortable={true}
            lockGroups={true}
            filterable={false}
            data={dataGrid}
            pageSize={pageSize}
            skip={page.skip}
            take={page.take}
            onPageChange={pageChange}
          >
            <Column
              field="data.postImage"
              title="Post"
              width="80px"
              cell={ImgCell}
            />
            <Column field="postTitle" title="Title" width="400px"/>
            <Column
              field="postTitle"
              title="Title"
              cell={props => <CommentCell {...props} />}
            />
          </Grid>
        </div>
      )}
    </>
  );
};
