import { createApi } from '@reduxjs/toolkit/query/react';
import { BASE_QUERY } from '../models';
import { YOUTUBE_CONNECT } from '@core/config/const';

export const youtubeConnectAPI = createApi({
  reducerPath: YOUTUBE_CONNECT,
  baseQuery: BASE_QUERY,
  endpoints: builder => ({
    connectToYoutube: builder.query<any, any>({
      query: params => ({
        url: `${YOUTUBE_CONNECT}/link`,
        params,
      }),
    }),
  }),
});

export const { useConnectToYoutubeQuery } = youtubeConnectAPI;
