import React from 'react';
import { useGetAllUsersQuery } from '@store/user';
import { UserList } from '@components/ui-kit/user-list';
import { USERS_COLLECTION } from '@core/config';
import { AddUser } from '../../components/ui-kit/add-user';
import { guard } from '@core/utils';

import './styles.scss';

const UsersPage: React.FC<any> = () => {
  const { data: usersList = [], isLoading } =
    useGetAllUsersQuery(USERS_COLLECTION);

  return (
    <div className="users-page">
      <AddUser />
      <UserList usersList={usersList} isLoading={isLoading} />
    </div>
  );
};

export const UsersGuard = guard(UsersPage);
