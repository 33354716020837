import React from 'react';

export const TitleCell: React.FC<any> = ({ ...props }) => {
  let styles = props.style;
  let classNames = props.className;

  return (
    <td style={{ textAlign: 'center', ...styles }} className={classNames}>
      <div className="inform-table__title-wrapper">
        <a target="_blank" href={props.dataItem.videoUrl} rel="noreferrer">
          {props.dataItem.title}
        </a>
      </div>
    </td>
  );
};
