import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import './styles.scss';

export const InsertNavigation: React.FC = () => {
  const location = useLocation();
  const [currentLink, setCurrentLink] = useState('');

  useEffect(() => {
    const { pathname } = location;

    if (pathname === '/tiktok' || pathname.startsWith('/tiktok/')) {
      setCurrentLink('/tiktok');
    } else if (
      pathname === '/instagram' ||
      pathname.startsWith('/instagram/')
    ) {
      setCurrentLink('/instagram');
    } else if (pathname === '/youtube' || pathname.startsWith('/youtube/')) {
      setCurrentLink('/youtube');
    } else if (pathname === '/all' || pathname.startsWith('/all/')) {
      setCurrentLink('/all');
    } else {
      setCurrentLink('');
    }
  }, [location]);

  return (
    <div className="inner-header">
      <nav>
        <ul>
          <li>
            <NavLink to={currentLink} end>
              Daily Stats
            </NavLink>
          </li>
          <li>
            <NavLink to={`${currentLink}/comments-tree`} end>
              Comments Tree
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};
