import React from 'react';
import './style.scss';

interface HoveredImageProps {
  imageUrl: string;
  alt: string;
  onClose: () => void;
}

const HoveredImage: React.FC<HoveredImageProps> = ({
  imageUrl,
  alt,
  onClose,
}) => {
  return (
    <>
      <div className="hovered-image-container">
        <img className="hovered-image" src={imageUrl} alt={alt} />
      </div>
    </>
  );
};

export default HoveredImage;
