import React, { useEffect, useState } from 'react';
import moment from 'moment';

import {
  DropDownList,
  DropDownListChangeEvent,
} from '@progress/kendo-react-dropdowns';

type PropsType = {
  setSearchParams: any;
  startDate: string;
  setStartDate: any;
  endDate: string;
  setEndDate: any;
};

export const PeriodPicker: React.FC<PropsType> = ({
  setSearchParams,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  type IPeriodVariableList = {
    text: string;
    id: number;
    day: number;
  };

  type IPeriodVariableListState = {
    value: IPeriodVariableList;
  };

  const periodVariableList: IPeriodVariableList[] = [
    { text: 'Yesterday', id: 1, day: 1 },
    { text: 'Last 7 days', id: 2, day: 7 },
    { text: 'Last 14 days', id: 3, day: 14 },
    { text: 'Last 28 days', id: 4, day: 28 },
    { text: 'Last 205 days', id: 5, day: 205 },
    { text: 'Full time', id: 6, day: 10000 },
  ];

  const [periodVariableListState, setPeriodVariableListState] =
    useState<IPeriodVariableListState>({
      value: { text: 'Last 7 days', id: 2, day: 7 },
    });

  const handleChange = (event: DropDownListChangeEvent) => {
    setPeriodVariableListState({
      value: event.target.value,
    });
    setEndDate(new Date().toISOString().slice(0, 10));
  };

  useEffect(() => {
    if (!!startDate) {
      setSearchParams({ start: `${startDate}`, end: `${endDate}` });
    }
  }, [endDate, setSearchParams, startDate, periodVariableListState]);

  useEffect(() => {
    if (!!endDate) {
      const days = moment().subtract(periodVariableListState.value.day, 'days');
      setStartDate(days.toISOString().slice(0, 10));
    }
  }, [periodVariableListState, endDate]);

  useEffect(() => {
    const startValue = moment()
      .subtract(periodVariableListState.value.day, 'days')
      .toISOString()
      .slice(0, 10);
    const endValue = new Date().toISOString().slice(0, 10); //
    setStartDate(startValue);
    setEndDate(endValue);
  }, []);

  return (
    <div>
      <DropDownList
        data={periodVariableList}
        textField="text"
        dataItemKey="id"
        value={periodVariableListState.value}
        onChange={handleChange}
        style={{ width: '300px' }}
      />
    </div>
  );
};
