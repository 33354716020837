import React, { useEffect, useState } from 'react';
import { Spinner } from '@components/ui-kit';
import { IPeriodChart, IFollowersChart } from '@store/models';
import {
  useGetTiktokFollowersChartQuery,
  useGetTiktokPeriodChartQuery,
} from '@store/tiktok';
import {
  useGetInstagramFollowersChartQuery,
  useGetInstagramPeriodChartQuery,
} from '@store/instagram';
import {
  useGetYoutubeFollowersChartQuery,
  useGetYoutubePeriodChartQuery,
} from '@store/youtube';
import { useMatch } from 'react-router-dom';

import './styles.scss';

import {
  Chart,
  ChartAxisDefaults,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTitle,
} from '@progress/kendo-react-charts';
import numbro from 'numbro';

type PropsType = {
  startDate: string;
  endDate: string;
};

export const ChartsBlock: React.FC<PropsType> = ({ startDate, endDate }) => {
  const MAX_LABELS = 28;

  const [TiktokPeriodData, setTiktokPeriodData] = useState<IPeriodChart[]>([]);
  const [InstagramPeriodData, setInstagramPeriodData] = useState<
    IPeriodChart[]
  >([]);
  const [YoutubePeriodData, setYoutubePeriodData] = useState<IPeriodChart[]>(
    [],
  );

  const [TiktokFollowersData, setTiktokFollowersData] = useState<
    IFollowersChart[]
  >([]);
  const [InstagramFollowersData, setInstagramFollowersData] = useState<
    IFollowersChart[]
  >([]);
  const [YoutubeFollowersData, setYoutubeFollowersData] = useState<
    IFollowersChart[]
  >([]);

  const { data: TiktokPeriodChart = [], isLoading: tiktokLoadingPeriodChart } =
    useGetTiktokPeriodChartQuery(
      startDate && endDate && { start: startDate, end: endDate },
    );
  const {
    data: YoutubePeriodChart = [],
    isLoading: youtubeLoadingPeriodChart,
  } = useGetYoutubePeriodChartQuery(
    startDate && endDate && { start: startDate, end: endDate },
  );

  const {
    data: InstagramPeriodChart = [],
    isLoading: instagramLoadingPeriodChart,
  } = useGetInstagramPeriodChartQuery(
    startDate && endDate && { start: startDate, end: endDate },
  );

  const { data: TiktokFollowersChart, isLoading: TiktokLoadingChart } =
    useGetTiktokFollowersChartQuery(
      startDate && endDate && { start: startDate, end: endDate },
    );
  const { data: instagramFollowersChart, isLoading: instagramLoadingChart } =
    useGetInstagramFollowersChartQuery(
      startDate && endDate && { start: startDate, end: endDate },
    );
  const { data: YoutubeFollowersChart, isLoading: YoutubeLoadingChart } =
    useGetYoutubeFollowersChartQuery(
      startDate && endDate && { start: startDate, end: endDate },
    );

  enum chartNames {
    views = 'Views',
    followers = 'Followers',
    totalFollowers = 'Total Followers',
  }

  useEffect(() => {
    if (!tiktokLoadingPeriodChart) setTiktokPeriodData(TiktokPeriodChart);
    if (!instagramLoadingPeriodChart)
      setInstagramPeriodData(InstagramPeriodChart);
    if (!youtubeLoadingPeriodChart) setYoutubePeriodData(YoutubePeriodChart);
  }, [
    TiktokPeriodChart,
    InstagramPeriodChart,
    YoutubePeriodChart,
    tiktokLoadingPeriodChart,
    instagramLoadingPeriodChart,
    youtubeLoadingPeriodChart,
  ]);

  useEffect(() => {
    if (!TiktokLoadingChart) setTiktokFollowersData(TiktokFollowersChart || []);
    if (!instagramLoadingChart)
      setInstagramFollowersData(instagramFollowersChart || []);
    if (!YoutubeLoadingChart)
      setYoutubeFollowersData(YoutubeFollowersChart || []);
  }, [
    TiktokFollowersChart,
    instagramFollowersChart,
    YoutubeFollowersChart,
    TiktokLoadingChart,
    instagramLoadingChart,
    YoutubeLoadingChart,
  ]);

  const sortedTiktokPeriodData = [...TiktokPeriodData].sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
  );
  const sortedInstagramPeriodData = [...InstagramPeriodData].sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
  );
  const sortedYoutubePeriodData = [...YoutubePeriodData].sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
  );

  const tiktokDates = sortedTiktokPeriodData.map(obj => obj.date);
  const instagramDates = sortedInstagramPeriodData.map(obj => obj.date);
  const youtubeDates = sortedYoutubePeriodData.map(obj => obj.date);

  const tiktokViewStats = sortedTiktokPeriodData.map((obj: IPeriodChart) => {
    return numbro(obj.viewStats).format({
      mantissa: 0,
    });
  });

  const instagramViewStats = sortedInstagramPeriodData.map(
    (obj: IPeriodChart) => {
      return numbro(obj.viewStats).format({
        mantissa: 0,
      });
    },
  );

  const youtubeViewStats = sortedYoutubePeriodData.map((obj: IPeriodChart) => {
    return numbro(obj.viewStats).format({
      mantissa: 0,
    });
  });

  const tiktokFollowersStats = sortedTiktokPeriodData.map(
    (obj: IPeriodChart) => {
      return numbro(obj.followersStats).format({
        mantissa: 0,
      });
    },
  );

  const instagramFollowersStats = sortedInstagramPeriodData.map(
    (obj: IPeriodChart) => {
      return numbro(obj.followersStats).format({
        mantissa: 0,
      });
    },
  );

  const youtubeFollowersStats = sortedYoutubePeriodData.map(
    (obj: IPeriodChart) => {
      return numbro(obj.followersStats).format({
        mantissa: 0,
      });
    },
  );

  const tiktokNumbers = tiktokFollowersStats
    .map(Number)
    .filter(num => !isNaN(num));
  const instagramNumbers = instagramFollowersStats
    .map(Number)
    .filter(num => !isNaN(num));
  const youtubeNumbers = youtubeFollowersStats
    .map(Number)
    .filter(num => !isNaN(num));

  const sortedTiktokFollowersData = [...TiktokFollowersData].sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
  );
  const sortedInstagramFollowersData = [...InstagramFollowersData].sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
  );
  const sortedYoutubeFollowersData = [...YoutubeFollowersData].sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
  );

  const tiktokTotalDates = sortedTiktokFollowersData.map(obj => obj.date);
  const instagramTotalDates = sortedInstagramFollowersData.map(obj => obj.date);
  const youtubeTotalDates = sortedYoutubeFollowersData.map(obj => obj.date);

  const tiktokTotalFollowersStats = sortedTiktokFollowersData.map(
    (obj: IFollowersChart) => {
      return numbro(obj.followersStats).format({
        thousandSeparated: true,
        mantissa: 0,
      });
    },
  );

  const instagramTotalFollowersStats = sortedInstagramFollowersData.map(
    (obj: IFollowersChart) => {
      return numbro(obj.followersStats).format({
        thousandSeparated: true,
        mantissa: 0,
      });
    },
  );

  const youtubeTotalFollowersStats = sortedYoutubeFollowersData.map(
    (obj: IFollowersChart) => {
      return numbro(obj.followersStats).format({
        thousandSeparated: true,
        mantissa: 0,
      });
    },
  );

  const tiktokTotalNumbers = tiktokTotalFollowersStats.map(formattedNumber =>
    parseInt(formattedNumber.replace(/,/g, ''), 10),
  );
  const instagramTotalNumbers = instagramTotalFollowersStats.map(
    formattedNumber => parseInt(formattedNumber.replace(/,/g, ''), 10),
  );
  const youtubeTotalNumbers = youtubeTotalFollowersStats.map(formattedNumber =>
    parseInt(formattedNumber.replace(/,/g, ''), 10),
  );

  const isTiktok = useMatch('/tiktok');
  const isInstagram = useMatch('/instagram');
  const isYoutube = useMatch('/youtube');
  const isAllPlatform = useMatch('/all');

  return (
    <div className="charts-block">
      {tiktokLoadingPeriodChart ||
      instagramLoadingPeriodChart ||
      youtubeLoadingPeriodChart ||
      TiktokLoadingChart ||
      instagramLoadingChart ||
      YoutubeLoadingChart ? (
        <Spinner />
      ) : (
        <>
          {isAllPlatform && (
            <>
              <div className="charts-block__container">
                <div className="elem">
                  <Chart style={{ height: '350px' }}>
                    <ChartTitle text={chartNames.views} position="top" />
                    <ChartLegend position="bottom" orientation="horizontal" />
                    <ChartAxisDefaults />
                    <ChartSeries>
                      <ChartSeriesItem
                        type="line"
                        style="smooth"
                        data={tiktokViewStats}
                        name="Tiktok"
                        color="red"
                        visible={true}
                        markers={{ visible: true, type: 'circle', size: 7 }}
                        tooltip={{
                          visible: true,
                        }}
                      />
                      <ChartSeriesItem
                        type="line"
                        style="smooth"
                        data={instagramViewStats}
                        name="Instagram"
                        color="blue"
                        visible={true}
                        markers={{ visible: true, type: 'circle', size: 7 }}
                        tooltip={{
                          visible: true,
                        }}
                      />
                      <ChartSeriesItem
                        type="line"
                        style="smooth"
                        data={youtubeViewStats}
                        name="Youtube"
                        color="green"
                        visible={true}
                        markers={{ visible: true, type: 'circle', size: 7 }}
                        tooltip={{
                          visible: true,
                        }}
                      />
                    </ChartSeries>
                    <ChartCategoryAxis>
                      <ChartCategoryAxisItem
                        categories={tiktokDates}
                        // title={{ text: chartNames.views }}
                        labels={{
                          format: 'd',
                          rotation: 'auto',
                          step: Math.ceil(tiktokDates.length / MAX_LABELS),
                          padding: { top: 10 },
                          position: 'start',
                        }}
                      />
                    </ChartCategoryAxis>
                  </Chart>
                </div>
                <div className="elem">
                  <Chart style={{ height: '350px' }}>
                    <ChartTitle text={chartNames.followers} position="top" />
                    <ChartLegend position="bottom" orientation="horizontal" />
                    <ChartAxisDefaults />
                    <ChartSeries>
                      <ChartSeriesItem
                        type="line"
                        style="smooth"
                        data={tiktokNumbers}
                        name="Tiktok"
                        color="red"
                        visible={true}
                        markers={{ visible: true, type: 'circle', size: 7 }}
                        tooltip={{
                          visible: true,
                        }}
                      />
                      <ChartSeriesItem
                        type="line"
                        style="smooth"
                        data={instagramNumbers}
                        name="Instagram"
                        color="blue"
                        visible={true}
                        markers={{ visible: true, type: 'circle', size: 7 }}
                        tooltip={{
                          visible: true,
                        }}
                      />
                      <ChartSeriesItem
                        type="line"
                        style="smooth"
                        data={youtubeNumbers}
                        name="Youtube"
                        color="green"
                        visible={true}
                        markers={{ visible: true, type: 'circle', size: 7 }}
                        tooltip={{
                          visible: true,
                        }}
                      />
                    </ChartSeries>
                    <ChartCategoryAxis>
                      <ChartCategoryAxisItem
                        categories={tiktokDates}
                        // title={{ text: chartNames.followers }}
                        labels={{
                          format: 'd',
                          rotation: 'auto',
                          step: Math.ceil(tiktokDates.length / MAX_LABELS),
                          padding: { top: 10 },
                          position: 'start',
                        }}
                      />
                    </ChartCategoryAxis>
                  </Chart>
                </div>
              </div>
              <div className="charts-block__container">
                <div className="elem">
                  <Chart style={{ height: '350px' }}>
                    <ChartTitle
                      text={chartNames.totalFollowers}
                      position="top"
                    />
                    <ChartLegend position="bottom" orientation="horizontal" />
                    <ChartAxisDefaults />
                    <ChartSeries>
                      <ChartSeriesItem
                        type="line"
                        style="smooth"
                        data={tiktokTotalNumbers}
                        name="Tiktok"
                        color="red"
                        visible={true}
                        markers={{ visible: true, type: 'circle', size: 7 }}
                        tooltip={{
                          visible: true,
                        }}
                      />
                      <ChartSeriesItem
                        type="line"
                        style="smooth"
                        data={instagramTotalNumbers}
                        name="Instagram"
                        color="blue"
                        visible={true}
                        markers={{ visible: true, type: 'circle', size: 7 }}
                        tooltip={{
                          visible: true,
                        }}
                      />
                      <ChartSeriesItem
                        type="line"
                        style="smooth"
                        data={youtubeTotalNumbers}
                        name="Youtube"
                        color="green"
                        visible={true}
                        markers={{ visible: true, type: 'circle', size: 7 }}
                        tooltip={{
                          visible: true,
                        }}
                      />
                    </ChartSeries>
                    <ChartCategoryAxis>
                      <ChartCategoryAxisItem
                        categories={tiktokTotalDates}
                        // title={{ text: chartNames.totalFollowers }}
                        labels={{
                          format: 'd',
                          rotation: 'auto',
                          step: Math.ceil(tiktokTotalDates.length / MAX_LABELS),
                          padding: { top: 10 },
                          position: 'start',
                        }}
                      />
                    </ChartCategoryAxis>
                  </Chart>
                </div>
              </div>
            </>
          )}
          {!isAllPlatform && (
            <>
              <div className="charts-block__container">
                <div className="elem">
                  <Chart style={{ height: 350 }}>
                    <ChartTitle text={chartNames.views} position="top" />
                    <ChartAxisDefaults />
                    <ChartSeries>
                      <ChartSeriesItem
                        type="line"
                        style="smooth"
                        data={
                          isTiktok
                            ? tiktokViewStats
                            : isInstagram
                              ? instagramViewStats
                              : youtubeViewStats
                        }
                        visible={true}
                        markers={{ visible: true, type: 'circle', size: 7 }}
                        tooltip={{
                          visible: true,
                        }}
                      />
                    </ChartSeries>
                    <ChartCategoryAxis>
                      <ChartCategoryAxisItem
                        categories={
                          isTiktok
                            ? tiktokDates
                            : isInstagram
                              ? instagramDates
                              : youtubeDates
                        }
                        // title={{ text: chartNames.views }}
                        labels={{
                          format: 'd',
                          rotation: 'auto',
                          step: Math.ceil(
                            (isTiktok
                              ? tiktokDates.length
                              : isInstagram
                                ? instagramDates.length
                                : youtubeDates.length) / MAX_LABELS,
                          ),
                          padding: { top: 10 },
                          position: 'start',
                        }}
                      />
                    </ChartCategoryAxis>
                  </Chart>
                </div>
                <div className="elem">
                  <Chart style={{ height: 350 }}>
                    <ChartTitle text={chartNames.followers} position="top" />
                    <ChartAxisDefaults />
                    <ChartSeries>
                      <ChartSeriesItem
                        type="line"
                        style="smooth"
                        data={
                          isTiktok
                            ? tiktokNumbers
                            : isInstagram
                              ? instagramNumbers
                              : youtubeNumbers
                        }
                        visible={true}
                        markers={{ visible: true, type: 'circle', size: 7 }}
                        tooltip={{
                          visible: true,
                        }}
                      />
                    </ChartSeries>
                    <ChartCategoryAxis>
                      <ChartCategoryAxisItem
                        categories={
                          isTiktok
                            ? tiktokDates
                            : isInstagram
                              ? instagramDates
                              : youtubeDates
                        }
                        // title={{ text: chartNames.followers }}
                        labels={{
                          format: 'd',
                          rotation: 'auto',
                          step: Math.ceil(
                            (isTiktok
                              ? tiktokDates.length
                              : isInstagram
                                ? instagramDates.length
                                : youtubeDates.length) / MAX_LABELS,
                          ),
                          padding: { top: 10 },
                          position: 'start',
                        }}
                      />
                    </ChartCategoryAxis>
                  </Chart>
                </div>
              </div>
              <div className="charts-block__container">
                <div className="elem">
                  <Chart style={{ height: 350 }}>
                    <ChartTitle
                      text={chartNames.totalFollowers}
                      position="top"
                    />
                    <ChartAxisDefaults />
                    <ChartSeries>
                      <ChartSeriesItem
                        type="line"
                        style="smooth"
                        data={
                          isTiktok
                            ? tiktokTotalNumbers
                            : isInstagram
                              ? instagramTotalNumbers
                              : youtubeTotalNumbers
                        }
                        visible={true}
                        markers={{ visible: true, type: 'circle', size: 7 }}
                        tooltip={{
                          visible: true,
                        }}
                      />
                    </ChartSeries>
                    <ChartCategoryAxis>
                      <ChartCategoryAxisItem
                        categories={
                          isTiktok
                            ? tiktokTotalDates
                            : isInstagram
                              ? instagramTotalDates
                              : youtubeTotalDates
                        }
                        // title={{ text: chartNames.totalFollowers }}
                        labels={{
                          format: 'd',
                          rotation: 'auto',
                          step: Math.ceil(
                            (isTiktok
                              ? tiktokTotalDates.length
                              : isInstagram
                                ? instagramTotalDates.length
                                : youtubeTotalDates.length) / MAX_LABELS,
                          ),
                          padding: { top: 10 },
                          position: 'start',
                        }}
                      />
                    </ChartCategoryAxis>
                  </Chart>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
