import { createApi } from '@reduxjs/toolkit/query/react';
import { BASE_QUERY } from '../models';
import { ASSISTENT_COMMENTS } from '@core/config/const';

export const assistantAPI = createApi({
  reducerPath: ASSISTENT_COMMENTS,
  baseQuery: BASE_QUERY,
  endpoints: (builder: { mutation: (arg0: { query: (params: any) => { url: string; params: any; }; }) => any; }) => ({
    assistantComments: builder.mutation({
      query: (params: any)=> ({
          url: ASSISTENT_COMMENTS,
          params,
      }),
    }),
  }),
});

export const { useAssistantCommentsMutation } = assistantAPI;
