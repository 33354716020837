import React, { useEffect, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { ComboBox, ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import { useUpdateTiktokPostTagMutation } from '@store/tiktok';
import { useUpdateInstagramReelTagMutation } from '@store/instagram';
import { useUpdateYoutubeVideoTagMutation } from '@store/youtube';

type PropsType = {
  postId: string;
  tagsArr: string[];
  PostDataTags: string;
  ReelDataTags: string;
  VideoDataTags: string;
};

export const DropDownBtn: React.FC<PropsType> = ({
  postId,
  tagsArr,
  PostDataTags,
  ReelDataTags,
  VideoDataTags,
}) => {
  const isTiktok = useMatch('/tiktok');
  const isInstagram = useMatch('/instagram');
  const isYoutube = useMatch('/youtube');

  const [currentTag, setCurrentTag] = useState<string>('');
  const [updatePostTag] = useUpdateTiktokPostTagMutation();
  const [updateReelsTag] = useUpdateInstagramReelTagMutation();
  const [updateVideoTag] = useUpdateYoutubeVideoTagMutation();

  const onChange = async (event: ComboBoxChangeEvent) => {
    const selectedTag = event.value as string;
    setCurrentTag(selectedTag);
    const values = { tags: selectedTag };

    if (isTiktok) {
      await updatePostTag({
        id: postId,
        data: values,
      });
    }
    if (isInstagram) {
      await updateReelsTag({
        id: postId,
        data: values,
      });
    }
    if (isYoutube) {
      await updateVideoTag({
        id: postId,
        data: values,
      });
    }
    setCurrentTag(values.tags);
  };

  useEffect(() => {
    if (isTiktok) {
      setCurrentTag(PostDataTags);
    }
    if (isInstagram) {
      setCurrentTag(ReelDataTags);
    }
    if (isYoutube) {
      setCurrentTag(VideoDataTags);
    }
  }, [
    PostDataTags,
    ReelDataTags,
    VideoDataTags,
    isInstagram,
    isTiktok,
    isYoutube,
  ]);

  return (
    <div className="drop-down-btn">
      <ComboBox
        style={{ width: '100%' }}
        data={tagsArr}
        id="postTags"
        allowCustom={true}
        value={currentTag}
        onChange={onChange}
        defaultValue=""
      />
    </div>
  );
};
