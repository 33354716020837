import { guard } from '@core/utils';
import './styles.scss';
import React from 'react';
import { CommentsNavigation } from '@components/ui-kit/comments-nav';
import { CommentsTable } from '@components/ui-kit/comments-table/comments-table';

const CommentsPage: React.FC<any> = () => {

  return (
    <div className="comments">
      <CommentsNavigation/>
      <CommentsTable/>
    </div>
  );
};

export const CommentsPageGuard = guard(CommentsPage);