import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from '@components/ui-kit';

import './styles.scss';

export const MainLayout: FC = () => {
  return (
    <>
      <div className="main-layout">
        <div className="main-layout-wrapper">
          <Header />
          <div className="content">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};
