import { createApi } from '@reduxjs/toolkit/query/react';
import {
  BASE_QUERY,
  IDailyStatsCollection,
  IPeriodChart,
  IPeriodStatistic,
  IFollowersChart,
} from '../models';
import {
  INSTAGRAM_DAILY_STATS,
  INSTAGRAM_PERIOD_CHART,
  INSTAGRAM_PERIOD_STATS,
} from '@core/config/const';

export const instagramDailyStatsAPI = createApi({
  reducerPath: INSTAGRAM_DAILY_STATS,
  baseQuery: BASE_QUERY,
  endpoints: builder => ({
    getInstagramDailyStats: builder.query<IDailyStatsCollection, any>({
      query: params => ({
        url: INSTAGRAM_DAILY_STATS,
        params,
      }),
    }),
    getInstagramPeriodStats: builder.query<IPeriodStatistic, any>({
      query: params => ({
        url: INSTAGRAM_PERIOD_STATS,
        params,
      }),
    }),
    getInstagramPeriodChart: builder.query<IPeriodChart[], any>({
      query: params => ({
        url: INSTAGRAM_PERIOD_CHART,
        params,
      }),
    }),
    getInstagramFollowersChart: builder.query<IFollowersChart[], any>({
      query: params => ({
        url: `${INSTAGRAM_PERIOD_CHART}/followers`,
        params,
      }),
    }),
  }),
});

export const {
  useGetInstagramDailyStatsQuery,
  useGetInstagramPeriodStatsQuery,
  useGetInstagramPeriodChartQuery,
  useGetInstagramFollowersChartQuery,
} = instagramDailyStatsAPI;
