import React from 'react';
import { Loader } from '@progress/kendo-react-indicators';

export const Spinner: React.FC = () => {
  return (
    <div className="col-6">
      <Loader type="converging-spinner" />
    </div>
  );
};
