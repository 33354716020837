import React from 'react';
import { NavLink } from 'react-router-dom';

import './styles.scss';

export const CommentsNavigation: React.FC = () => {
  return (
    <div className="inner-header">
      <nav>
        <ul>
          <li>
            <NavLink to='tiktok' end>
              Tiktok
            </NavLink>
          </li>
          <li>
            <NavLink to='instagram' end>
              Instagram
            </NavLink>
          </li>
          <li>
            <NavLink to='youtube' end>
              Youtube
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};
