import React from 'react';
import moment from 'moment';

export const DateCell: React.FC<any> = ({ ...props }) => {
  let styles = props.style;
  let classNames = props.className;
  const date = props.dataItem.recordCreated;
  const convertedDate = moment(date).format('MMMM Do YYYY, h:mm:ss a');

  return (
    <td style={{ textAlign: 'center', ...styles }} className={classNames}>
      {convertedDate}
    </td>
  );
};
