import React, { useState } from 'react';
import { Upload, UploadActionsLayout } from '@progress/kendo-react-upload';

import './styles.scss';

export const Downloads = () => {
  const [actionsLayout, setActionsLayout] =
    React.useState<UploadActionsLayout>('stretched');

  return (
    <div className="example">
      <div className="upload">
        <Upload
          autoUpload={false}
          actionsLayout={actionsLayout}
          defaultFiles={[]}
          withCredentials={false}
          saveUrl={'https://demos.telerik.com/kendo-ui/service-v4/upload/save'}
          removeUrl={
            'https://demos.telerik.com/kendo-ui/service-v4/upload/remove'
          }
        />
      </div>

      <style>{`
        .example {
            display: flex;
            gap: 20px;
        }
        .upload { flex: 1 0 60%; }
        .example-config { flex: 1 0 30%; }
        `}</style>
    </div>
  );
};
