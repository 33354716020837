import React, { useEffect, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { usePostInstagramCommentMutation } from '@store/instagram';
import { usePostTiktokCommentMutation } from '@store/tiktok';
import { usePostYoutubeCommentMutation } from '@store/youtube';
import { Button } from '@progress/kendo-react-buttons';
import { Input } from '@progress/kendo-react-inputs';
import { useAssistantCommentsMutation } from '@store/assistant';
import './styles.scss';
import { Loader } from '@progress/kendo-react-indicators';

interface GenText {
  text: {
    value: string;
  };
  type: string;
}

export const CommentCell: React.FC<any> = ({ ...props }) => {
  const [liked, setLiked] = useState(false);
  const [commentText, setCommentText] = useState('');
  const isTiktok = useMatch('/tiktok/comments-tree');
  const isInstagram = useMatch('/instagram/comments-tree');
  const isYoutube = useMatch('/youtube/comments-tree');
  const isCommentsTiktok = useMatch('/comments/tiktok');
  const isCommentsInstagram = useMatch('/comments/instagram');
  const isCommentsYoutube = useMatch('/comments/youtube');
  const { dataItem, desc } = props;
  const [assistantComments] = useAssistantCommentsMutation();
  const [loading, setLoading] = useState(false);
  const [replied, setReplied] = useState(false);


  useEffect(() => {
    if (dataItem.data.replied !== undefined) {
      setReplied(dataItem.data.replied);
    } else {
      setReplied(dataItem.replied || false);
    }
  }, [dataItem.data.replied, dataItem.replied]);

  const handleLikeClick = () => {
    setLiked(!liked);
  };
  const textGenerate = async () => {
    setLoading(true);
    try {
      if (isTiktok || isCommentsTiktok) {
        const text = dataItem.data ? dataItem.data.text : '';
        const userid = dataItem.data ? dataItem.data.user.uid : '';
        try {
          const result: GenText = (await assistantComments({
            id: userid,
            text: text,
            type: 'isTiktok',
            title: dataItem.data.share_info.title,
          }).unwrap()) as GenText;
          setCommentText(result && result.text ? result.text.value : '');
        } catch (error) {
          console.error(error);
        }
      } else if (isInstagram || isCommentsInstagram) {
        const userid = dataItem.data ? dataItem.data._data.from.id : '';
        const text = dataItem.data ? dataItem.data.text : '';
        try {
          const result: GenText = (await assistantComments({
            id: userid,
            text: text,
            type: 'isInstagram',
            title: desc ? desc[0].title : dataItem.postTitle,
          }).unwrap()) as GenText;
          setCommentText(result && result.text ? result.text.value : '');
        } catch (error) {
          console.error(error);
        }
      } else if (isYoutube || isCommentsYoutube) {
        const text = dataItem.data
          ? dataItem.data.topLevelComment.snippet.textDisplay
          : '';
        const userid = dataItem.data
          ? dataItem.data.topLevelComment.snippet.authorDisplayName
          : '';
        try {
          const result: GenText = (await assistantComments({
            id: userid,
            text: text,
            type: 'isYoutube',
            title: desc ? desc[0].title : dataItem.postTitle,
          }).unwrap()) as GenText;
          setCommentText(result && result.text ? result.text.value : '');
        } catch (error) {
          console.error(error);
        }
      }
    } finally {
      setLoading(false); // Set loading to false when textGenerate completes (either success or failure)
    }
  };
  const [postCommentYoutube] = usePostYoutubeCommentMutation();
  const [postCommentInstagram] = usePostInstagramCommentMutation();
  const [postCommentTiktok] = usePostTiktokCommentMutation();

  const handleSendComment = async () => {
    setCommentText('');

    if (isTiktok || isCommentsTiktok) {
      const id = dataItem.data ? dataItem.data.aweme_id : '';
      const videoId = dataItem.data ? dataItem.data.cid : '';

      await postCommentTiktok({
        parent_id: id,
        text: commentText,
        media_id: videoId,
      });
    } else if (isYoutube || isCommentsYoutube) {
      const id = dataItem.data ? dataItem.data.topLevelComment.id : '';
      const videoId = dataItem.data
        ? dataItem.data.topLevelComment.snippet.videoId
        : '';
      await postCommentYoutube({
        parent_id: id,
        text: commentText,
        media_id: videoId,
      });
    } else if (isInstagram || isCommentsInstagram) {
      const id = dataItem.data ? dataItem.data.id : '';

      await postCommentInstagram({
        text: commentText,
        media_id: id, 
      });
    }
    setCommentText('');
  };
  let text = '';

  if (dataItem && dataItem.data && dataItem.data.text) {
    text = dataItem.data.text;
  } else if (
    dataItem && dataItem.data && dataItem.data.topLevelComment
      ? dataItem.data.topLevelComment.snippet.textDisplay
      : ''
  ) {
    text = dataItem.data.topLevelComment.snippet.textDisplay || '';
  } else {
    text = '';
  }

  const handleChange = (e: any) => {
    setCommentText(e.target.value);
  };

  return (
    <td>
      <div className="comments-cell">
        {text !== '' ? (
          <>
            <span>{text}</span>
            <div className="comments-cell__send">
              <Button onClick={textGenerate}>
                {loading ? (
                  <Loader />
                ) : (
                  <span className="k-icon k-font-icon k-i-rotate"></span>
                )}
              </Button>
              <div className="btn" onClick={handleLikeClick}>
                {liked ? (
                  <span
                    className="button k-icon k-font-icon k-i-heart"
                    onClick={handleLikeClick}
                  ></span>
                ) : (
                  <span
                    className="button k-icon k-font-icon k-i-heart-outline"
                    onClick={handleLikeClick}
                  ></span>
                )}
              </div>
              <Input disabled={replied} value={commentText} onChange={handleChange} />
              <Button disabled={replied} onClick={handleSendComment}>Send</Button>
            </div>
          </>
        ) : (
          ''
        )}
      </div>
    </td>
  );
};

export default CommentCell;
