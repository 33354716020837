import * as React from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { useNavigate } from 'react-router-dom';
import { setRole, setUser } from '@store/user';
import { useEffect } from 'react';
import { Spinner } from '@components/ui-kit';
import { useAppDispatch } from '@core/hooks';
import { useLoginUserMutation } from '@store/auth/auth-api';

import './styles.scss';

export const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [password, setPassword] = React.useState<string>('');
  const [userName, setUserName] = React.useState<string>('');
  const [success, setSuccess] = React.useState<boolean>(false);

  const usernameValidationMessage: string =
    'Your username should contain only letters!';
  const passwordValidationMessage: string =
    'Please enter password between 6 and 16 characters!';

  const [loginUser, { data: loggedUser, isLoading, isSuccess }] =
    useLoginUserMutation();

  const handleChange = (event: any) => {
    if (event.target.name === 'password') {
      setPassword(event.target.value);
    } else if (event.target.name === 'userName') {
      setUserName(event.target.value);
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (userName && password) {
      await loginUser({ userName, password });
      dispatch(setUser({ userName }));
      setSuccess(true);

      localStorage.setItem(
        'user',
        JSON.stringify({ userName, loggedIn: true }),
      );
    } else {
      alert('Username or password is empty');
    }
  };

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const { loggedIn } = JSON.parse(storedUser);
      const { userName } = JSON.parse(storedUser);

      if (loggedIn) {
        dispatch(setUser({ userName }));
        setSuccess(true);
        navigate('/tiktok');
      }
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(setRole(loggedUser?.userRole));
      navigate('/tiktok');
    }
  }, [dispatch, isSuccess, loggedUser, navigate]);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="login">
          <div className="row example-wrapper">
            <div className="col-xs-12 col-sm-6 offset-sm-3 example-col">
              <form className="k-form" onSubmit={handleSubmit}>
                <fieldset>
                  <legend>Login:</legend>
                  <div className="mb-3">
                    <Input
                      validityStyles={false}
                      onChange={handleChange}
                      name="userName"
                      style={{ width: '100%' }}
                      label="First Name"
                      pattern={'[A-Za-z]+'}
                      minLength={2}
                      required={true}
                      validationMessage={usernameValidationMessage}
                    />
                  </div>
                  <div className="mb-3">
                    <Input
                      validityStyles={false}
                      value={password}
                      onChange={handleChange}
                      name="password"
                      type="password"
                      style={{ width: '100%' }}
                      label="Password"
                      required={true}
                      minLength={6}
                      maxLength={18}
                      validationMessage={passwordValidationMessage}
                    />
                  </div>
                </fieldset>
                <input
                  type="submit"
                  style={{ marginTop: '1rem' }}
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                  value="Login"
                />
              </form>
            </div>
            {success && (
              <div
                className="alert alert-success"
                style={{ position: 'absolute', marginTop: '1rem' }}
              >
                Check login or password!
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
