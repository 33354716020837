import React, { useEffect, useState } from 'react';
import {
  ListView,
  ListViewFooter,
  ListViewHeader,
  ListViewItemProps,
} from '@progress/kendo-react-listview';
import { IUser, IUserRole } from '@store/models';
import { Spinner } from '../spinner';
import { USERS_COLLECTION } from '@core/config';
import { useGetAllUsersQuery } from '@store/index';
import { UserDeleteModal } from '../user-delete-modal';
import { UserUpdateModal } from '../user-update-modal';

import './styles.scss';

const MyHeader: React.FC = () => {
  return (
    <ListViewHeader
      style={{ color: 'rgb(160, 160, 160)', fontSize: 14 }}
      className="pl-3 pb-2 pt-2"
    >
      Users list
    </ListViewHeader>
  );
};

const MyFooter = () => {
  const { data = [] } = useGetAllUsersQuery(USERS_COLLECTION);
  const userListLength = data?.length;
  return (
    <ListViewFooter
      style={{
        color: 'rgb(160, 160, 160)',
        fontSize: 14,
      }}
      className="pl-3 pb-2 pt-2"
    >
      {userListLength} Users total
    </ListViewFooter>
  );
};

const MyItemRender: React.FC<ListViewItemProps> = (
  props: ListViewItemProps,
) => {
  const currentUser: IUser = props.dataItem;
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    if (currentUser?.userRole === IUserRole.admin) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [currentUser]);

  const [visibleEditModal, setVisibleEditModal] =
    React.useState<boolean>(false);

  const handleCloseEditModal = () => {
    setVisibleEditModal(!visibleEditModal);
  };

  const [visibleDeleteModal, setVisibleDeleteModal] =
    React.useState<boolean>(false);

  const handleDeleteEditModal = () => {
    setVisibleDeleteModal(!visibleDeleteModal);
  };

  return (
    <>
      <UserUpdateModal
        currentUser={currentUser}
        visible={visibleEditModal}
        handleClose={handleCloseEditModal}
      />
      <UserDeleteModal
        currentUser={currentUser}
        visible={visibleDeleteModal}
        handleClose={handleDeleteEditModal}
      />
      <div className="user-list">
        <div className="k-listview-item row p-2 border-bottom align-middle user-list__content">
          <div className="col-6">
            <h2
              style={{
                color: '#454545',
              }}
              className="text-uppercase"
            >
              {currentUser?.userName}
            </h2>
            <div
              style={{
                fontSize: 14,
                color: '#a0a0a0',
              }}
            >
              {currentUser?.userRole}
            </div>
          </div>
          {isAdmin ? (
            ''
          ) : (
            <div className="col-4 user-list__content__btn-block">
              <div className="k-chip k-chip-md k-rounded-md k-chip-solid k-chip-solid-base">
                <div
                  onClick={() => handleCloseEditModal()}
                  className="k-chip-content"
                >
                  Edit
                </div>
              </div>
              <div className="k-chip k-chip-md k-rounded-md k-chip-solid k-chip-solid-base">
                <div
                  onClick={() => handleDeleteEditModal()}
                  className="k-chip-content"
                >
                  Delete
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

type PropsType = {
  usersList: IUser[];
  isLoading: boolean;
};

export const UserList: React.FC<PropsType> = ({ usersList, isLoading }) => {
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="user-list">
          <ListView
            data={usersList}
            item={MyItemRender}
            style={{ width: '100%' }}
            header={MyHeader}
            footer={MyFooter}
          />
        </div>
      )}
    </>
  );
};
