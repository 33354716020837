import { createSlice } from '@reduxjs/toolkit';
import { IUserRole } from '@store/models';

export interface IUserResponse {
  user: string | null | undefined;
  userRole: IUserRole;
}

const initialState: IUserResponse = {
  user: null,
  userRole: IUserRole.user,
};

const userSlice = createSlice({
  initialState,
  name: 'user',
  reducers: {
    setUser(state, action) {
      state.user = action.payload.userName;
    },
    setRole(state, action) {
      state.userRole = action.payload;
    },
    logOutUser(state) {
      state.user = null;
      state.userRole = IUserRole.user;
    },
  },
});

export const { setUser, setRole, logOutUser } = userSlice.actions;
export default userSlice.reducer;
