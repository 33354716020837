import React, { useEffect, useState } from 'react';
import {
  AppBar,
  AppBarSection,
  AppBarSpacer,
} from '@progress/kendo-react-layout';
import { NavLink, useNavigate } from 'react-router-dom';
import { DropDownButton } from '@progress/kendo-react-buttons';
import { useAppDispatch, useAppSelector } from '@core/hooks';
import { logOutUser } from '@store/user';
import { IUserRole } from '@store/models';

import './styles.scss';

export const Header: React.FC = () => {
  const redirect = useNavigate();
  const dispatch = useAppDispatch();
  const currentUserName = useAppSelector(state => state.user.user);
  const currentUserRole = useAppSelector(state => state.user.userRole);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const { userName } = JSON.parse(storedUser);
      if (userName === 'ADMIN') {
        setIsAdmin(true);
      }
    }
  });

  useEffect(() => {
    if (currentUserRole === IUserRole.admin) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [currentUserRole]);

  const logOut = () => {
    dispatch(logOutUser());
    localStorage.removeItem('user');
    redirect('./auth');
  };

  const toHomePage = () => {
    redirect('/');
  };

  const userMenuItems = [
    { text: 'Profile', route: '/profile' },
    { text: 'Users', route: '/users' },
    { text: 'Parser History', route: '/parserHistory' },
    { text: 'Comments', route: '/comments/tiktok' },
    { text: 'Downloads', route: '/downloads' },
    { text: 'Social Connect', route: '/connectTo' },
    { text: 'Log out', route: '/auth', action: logOut },
  ];

  const handleUserMenuClick = (e: any) => {
    if (e.item.action) {
      e.item.action();
    } else {
      redirect(e.item.route);
    }
  };

  return (
    <>
      <AppBar>
        <AppBarSpacer style={{ width: 4 }} />
        <AppBarSection>
          <h1 onClick={toHomePage} className="header-title">
            Social media analytics
          </h1>
        </AppBarSection>
        <AppBarSpacer style={{ width: 32 }} />
        <AppBarSection className="header-info-section">
          <nav>
            <ul>
              <li>
                <NavLink to="/all">All</NavLink>
              </li>
              <li>
                <NavLink to="/tiktok">Tiktok</NavLink>
              </li>
              <li>
                <NavLink to="/instagram">Instagram</NavLink>
              </li>
              <li>
                <NavLink to="/youtube">YouTube</NavLink>
              </li>
            </ul>
          </nav>
        </AppBarSection>
        <AppBarSpacer />
        <AppBarSection className="actions">
          <DropDownButton
            items={userMenuItems.filter(
              item =>
                isAdmin ||
                item.text === 'Profile' ||
                item.text === 'Log out' ||
                item.text === 'Parser History' ||
                item.text === 'Comments',
            )}
            text={currentUserName}
            onItemClick={handleUserMenuClick}
          />
        </AppBarSection>
      </AppBar>
    </>
  );
};
