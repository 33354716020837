import { createApi } from '@reduxjs/toolkit/query/react';
import { BASE_QUERY, IUser } from '../models';
import { USERS_COLLECTION } from '@core/config/const';

export const usersAPI = createApi({
  reducerPath: USERS_COLLECTION,
  tagTypes: ['Users'],
  baseQuery: BASE_QUERY,
  endpoints: builder => ({
    getAllUsers: builder.query<IUser[], any>({
      query: params => ({
        url: USERS_COLLECTION,
        method: 'GET',
        params,
      }),
      providesTags: ['Users'],
    }),
    createUser: builder.mutation<IUser, any>({
      query(data) {
        return { url: USERS_COLLECTION, method: 'POST', body: data };
      },
      invalidatesTags: ['Users'],
    }),
    getCurrentUser: builder.query<IUser, any>({
      query: () => {
        return {
          url: `${USERS_COLLECTION}/profile`,
          method: 'GET',
        };
      },
    }),
    updateUser: builder.mutation<IUser, any>({
      query({ id, data }) {
        return {
          url: `${USERS_COLLECTION}/${id}`,
          method: 'PATCH',
          body: data,
        };
      },
      invalidatesTags: ['Users'],
    }),
    removeUser: builder.mutation<IUser, any>({
      query({ id }) {
        return {
          url: `${USERS_COLLECTION}/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Users'],
    }),
  }),
});

export const {
  useGetAllUsersQuery,
  useCreateUserMutation,
  useGetCurrentUserQuery,
  useUpdateUserMutation,
  useRemoveUserMutation,
} = usersAPI;
