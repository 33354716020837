import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

export const CommentsCell: React.FC<any> = ({ ...props }) => {
  let styles = props.style;
  let classNames = props.className;

  const postId = props.dataItem.postId;
  const comments: number = props.dataItem.comments;
  const setPost_id = props.setPost_id;

  const location = useLocation();
  const [currentLink, setCurrentLink] = useState('');

  useEffect(() => {
    const { pathname } = location;

    if (pathname === '/tiktok' || pathname.startsWith('/tiktok/')) {
      setCurrentLink('/tiktok');
    } else if (
      pathname === '/instagram' ||
      pathname.startsWith('/instagram/')
    ) {
      setCurrentLink('/instagram');
    } else if (
      pathname === '/youtube' ||
      pathname.startsWith('/youtube/')
    ) {
      setCurrentLink('/youtube');
    }else {
      setCurrentLink('');
    }
  }, [location]);

  const setPostIdParam = () => {
    setPost_id(postId);
    localStorage.setItem('post_id', postId);
    console.log(postId)
  };

  return (
    <td
      style={{ textAlign: 'center', ...styles }}
      className={classNames}
      onClick={setPostIdParam}
    >
      <NavLink to={`${currentLink}/comments-tree`} end>
        <div className="inform-table__comments-wrapper">
          {Math.trunc(comments)}
        </div>
      </NavLink>
    </td>
  );
};
