import React from 'react';
import { guard } from '@core/utils';

import './styles.scss';

export const Error: React.FC = () => {
  return <div className="error">Something was wrong</div>;
};

export const ErrorGuard = guard(Error);
