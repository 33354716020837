import { createApi } from '@reduxjs/toolkit/query/react';
import { BASE_QUERY } from '../models';
import { INSTAGRAM_CONNECT } from '@core/config/const';

export const instagramConnectAPI = createApi({
  reducerPath: INSTAGRAM_CONNECT,
  baseQuery: BASE_QUERY,
  endpoints: builder => ({
    getInstagramToken: builder.query<any, any>({
      query: params => ({
        url: `${INSTAGRAM_CONNECT}/get-token`,
        params,
      }),
    }),
    connectToInstagram: builder.query<any, any>({
      query: params => ({
        url: `${INSTAGRAM_CONNECT}/link`,
        params,
      }),
    }),
  }),
});

export const { useGetInstagramTokenQuery, useConnectToInstagramQuery } =
  instagramConnectAPI;
