import React, { useEffect } from 'react';

import {
  DateRangePicker,
  DateRangePickerChangeEvent,
  SelectionRange,
} from '@progress/kendo-react-dateinputs';

import './styles.scss';

type PropsType = {
  setSearchParams: any;
  startDate: string;
  setStartDate: any;
  endDate: string;
  setEndDate: any;
};

export const DatePicker: React.FC<PropsType> = ({
  setSearchParams,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  const [dateRange, setDateRange] = React.useState<SelectionRange>({
    start: null,
    end: null,
  });
  const handleDataRangeChange = (event: DateRangePickerChangeEvent) => {
    setDateRange(event.value);
  };

  useEffect(() => {
    setDateRange(dateRange);
    if (!!dateRange?.start && !!dateRange?.end) {
      setStartDate(dateRange?.start?.toISOString().slice(0, 10));
      setEndDate(dateRange?.end?.toISOString().slice(0, 10));
      setSearchParams({ start: `${startDate}`, end: `${endDate}`});
    }
  }, [dateRange, endDate, setSearchParams, startDate]);

  return (
    <div className="date-picker">
      <DateRangePicker value={dateRange} onChange={handleDataRangeChange} />
    </div>
  );
};
