import React from 'react';
import './styles.scss';


export const ImgCommentsCell: React.FC<any> = ({ ...props }) => {
  let imageElement;
    imageElement = (
      <img
        src={props?.dataItem.postImage}
        alt={props?.dataItem.postTitle}
      />
    );
  return (
    <td style={{ textAlign: 'center'}}>
      <div className="img-wrapper">{imageElement}</div>
    </td>
  );
};
