import { createApi } from '@reduxjs/toolkit/query/react';
import { BASE_QUERY } from '../models';
import { TIKTOK_CONNECT } from '@core/config/const';

export const tiktokConnectAPI = createApi({
  reducerPath: TIKTOK_CONNECT,
  baseQuery: BASE_QUERY,
  endpoints: builder => ({
    connectToTiktok: builder.query<any, any>({
      query: params => ({
        url: `${TIKTOK_CONNECT}/link`,
        params,
      }),
    }),
  }),
});

export const { useConnectToTiktokQuery } = tiktokConnectAPI;
