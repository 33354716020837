import React, { useState } from 'react';
import HoveredImage from './hovered-image'; // Импортируйте компонент

export const ImgCell: React.FC<any> = ({ ...props }) => {
  const [isHovered, setIsHovered] = useState(false);
  let styles = props.style;
  let classNames = props.className;

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const srtVideo = async () => {
    props.onClick();
    props.setCurrentLink(props.dataItem.videoUrl);
  };

  return (
    <td style={{...styles }} className={classNames} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div className="inform-table__img-wrapper" style={{...styles }}>
        <img
          onClick={srtVideo}
          src={props.dataItem.imageUrl}
          alt={props.dataItem.title}
        />
      </div>
      {isHovered && (
        <HoveredImage
          imageUrl={props.dataItem.imageUrl}
          alt={props.dataItem.title}
          onClose={() => setIsHovered(false)}
        />
      )}
    </td>
  );
};





// import React from 'react';

// export const ImgCell: React.FC<any> = ({ ...props }) => {
//   let styles = props.style;
//   let classNames = props.className;

//   const srtVideo = async () => {
//     props.onClick();
//     props.setCurrentLink(props.dataItem.videoUrl);
//   };

//   return (
//     <td style={{...styles }} className={classNames}>
//       <div className="inform-table__img-wrapper" style={{...styles }}>
//         <img
//           onClick={srtVideo}
//           src={props.dataItem.imageUrl}
//           alt={props.dataItem.title}
//         />
//       </div>
//     </td>
//   );
// };
