import React, { useEffect } from 'react';
import { Spinner } from '../spinner';
import {
  Grid,
  GridColumn as Column,
  GridPageChangeEvent,
} from '@progress/kendo-react-grid';
import { useGetParserHistoryQuery } from '@store/parser-history';
import { DataResult } from '@progress/kendo-data-query';

import './styles.scss';

const pageSize: number = 20;

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = {
  take: pageSize,
  skip: 0,
};

export const HistoryList = () => {
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const { data: parserHistoryData = [], isLoading } =
    useGetParserHistoryQuery(page);

  const [historyList, setHistoryList] = React.useState<DataResult>({
    data: parserHistoryData[0]
      ? parserHistoryData[0].map(item => ({
          ...item,
          recordCreated: new Date(item.recordCreated).toLocaleString('en-GB', {
            hour12: false,
          }),
          parserStart: new Date(item.parserStart).toLocaleString('en-GB', {
            hour12: false,
          }),
          time: msToTime(item.time),
        }))
      : [],
    total: parserHistoryData[1] ? parserHistoryData[1] : 0,
  });

  function msToTime(duration: number) {
    let milliseconds = Math.floor((duration % 1000) / 100);
    let seconds = Math.floor((duration / 1000) % 60);
    let minutes = Math.floor((duration / (1000 * 60)) % 60);
    let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    const formattedMilliseconds = milliseconds.toString().padStart(2, '0');
    const formattedSeconds =
      seconds > 0 ? seconds.toString().padStart(2, '0') + 's : ' : '';
    const formattedMinutes =
      minutes > 0 ? minutes.toString().padStart(2, '0') + 'm : ' : '';
    const formattedHours =
      hours > 0 ? hours.toString().padStart(2, '0') + 'h : ' : '';

    if (formattedHours) {
      return `${formattedHours}${formattedMinutes}${formattedSeconds}${formattedMilliseconds}ms`;
    } else if (formattedMinutes) {
      return `${formattedMinutes}${formattedSeconds}${formattedMilliseconds}ms`;
    } else if (formattedSeconds) {
      return `${formattedSeconds}${formattedMilliseconds}ms`;
    } else {
      return `${formattedMilliseconds}ms`;
    }
  }

  useEffect(() => {
    setHistoryList({
      data: parserHistoryData[0]
        ? parserHistoryData[0].map(item => ({
            ...item,
            recordCreated: new Date(item.recordCreated).toLocaleString(
              'en-GB',
              {
                hour12: false,
              },
            ),
            parserStart: new Date(item.parserStart).toLocaleString('en-GB', {
              hour12: false,
            }),
            time: msToTime(item.time),
          }))
        : [],
      total: parserHistoryData[1] ? parserHistoryData[1] : 0,
    });
  }, [parserHistoryData]);

  const pageChange = (event: GridPageChangeEvent) => {
    setPage({
      ...event.page,
    });
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="history-list">
          <Grid
            pageable={true}
            sortable={true}
            lockGroups={true}
            filterable={false}
            data={historyList}
            pageSize={pageSize}
            skip={page.skip}
            take={page.take}
            onPageChange={pageChange}
          >
            <Column field="parserName" title="Name" width="180px" />
            <Column
              field="parserStart"
              title="Start"
              format="{0:dd.MM.yyyy HH:mm:ss}"
              width="150px"
            />
            <Column
              field="recordCreated"
              title="End"
              format="{0:dd.MM.yyyy HH:mm:ss}"
              width="150px"
            />
            <Column field="time" title="Duration" width="160px" />
            <Column field="status" title="Status" width="250px" />
            <Column field="comments" title="Comments" width="100px" />
            <Column field="followers" title="Followers" width="100px" />
            <Column field="videos" title="Videos" />
            {/* <Column field="posts" title="Posts" /> */}
          </Grid>
        </div>
      )}
    </>
  );
};
