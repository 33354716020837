import React, { useEffect, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { Spinner } from '@components/ui-kit/spinner';
import { useGetInstagramReelTagsQuery } from '@store/instagram';
import { useGetYoutubeVideosTagsQuery } from '@store/youtube';
import { useGetTiktokPostsTagsQuery } from '@store/tiktok';
import { DropDownBtn } from '../drop-down-btn';
import { IVideoPost } from '@store/models';

export const TagsCell: React.FC<any> = ({ ...props }) => {
  const [tagsArr, setTagsArr] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  let styles = props.style;
  let classNames = props.className;
  const postId = props.dataItem.postId;

  const PostData: IVideoPost = props.PostsData.find(
    (post: IVideoPost) => post._id === postId,
  );
  const ReelData: IVideoPost = props.ReelsData.find(
    (reel: IVideoPost) => reel._id === postId,
  );
  const VideoData: IVideoPost = props.VideoData?.find(
    (video: IVideoPost) => video._id === postId,
  );

  const isTiktok = useMatch('/tiktok');
  const isInstagram = useMatch('/instagram');
  const isYoutube = useMatch('/youtube');

  const { data: ReelsDataTags = [], isLoading: isInstagramLoading } =
    useGetInstagramReelTagsQuery(null);

  const { data: PostsDataTags = [], isLoading: isTiktokLoading } =
    useGetTiktokPostsTagsQuery(null);

  const { data: VideosDataTags = [], isLoading: isYoutubeLoading } =
    useGetYoutubeVideosTagsQuery(null);

  useEffect(() => {
    if (isTiktok) {
      setTagsArr(PostsDataTags);
      setLoading(isTiktokLoading);
    }
    if (isInstagram) {
      setTagsArr(ReelsDataTags);
      setLoading(isInstagramLoading);
    }
    if (isYoutube) {
      setTagsArr(VideosDataTags);
      setLoading(isYoutubeLoading);
    }
  }, [
    isTiktok,
    isInstagram,
    isYoutube,
    isTiktokLoading,
    isInstagramLoading,
    isYoutubeLoading,
    PostsDataTags,
    ReelsDataTags,
    VideosDataTags,
  ]);

  return (
    <td style={{ textAlign: 'center', ...styles }} className={classNames}>
      {loading ? (
        <Spinner />
      ) : (
        <DropDownBtn
          postId={postId}
          tagsArr={tagsArr}
          PostDataTags={PostData?.tags}
          ReelDataTags={ReelData?.tags}
          VideoDataTags={VideoData?.tags}
        />
      )}
    </td>
  );
};
