import React, { useState } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { useUpdateUserMutation } from '@store/user';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '@core/hooks';
import { useEffect } from 'react';
import { IUser, IUserRole } from '@store/models';

type PropsType = {
  currentUser: IUser;
  visible: boolean;
  handleClose: () => void;
};

export const UserUpdateModal: React.FC<PropsType> = ({
  currentUser,
  visible,
  handleClose,
}) => {
  const [userName, setUserName] = React.useState<string>(currentUser?.userName);
  const [password, setPassword] = React.useState<string>('your old password');
  const currentUserRole = useAppSelector(state => state?.user.userRole);
  const [isCurrentAdmin, setIsCurrentIsAdmin] = useState(false);
  const redirect = useNavigate();
  const [updateUser] = useUpdateUserMutation();

  useEffect(() => {
    if (currentUserRole === IUserRole.admin) {
      setIsCurrentIsAdmin(true);
    } else {
      setIsCurrentIsAdmin(false);
    }
  }, [currentUserRole]);

  useEffect(() => {
    setUserName(currentUser?.userName);
  }, [currentUser]);

  const values = { userName: userName, password: password };
  const updateProfile = async () => {
    await updateUser({
      id: currentUser?._id,
      data: values,
    });
    handleClose();
    if (!isCurrentAdmin) {
      redirect('/auth');
    }
  };

  const handleChange = (event: any) => {
    if (event.target.name === 'password') {
      setPassword(event.target.value);
    } else if (event.target.name === 'userName') {
      setUserName(event.target.value);
    }
  };

  return (
    <div>
      {visible && (
        <Dialog height={250} width={450} onClose={handleClose}>
          <p style={{ margin: '25px', textAlign: 'center' }}>
            Edit {userName} information
          </p>
          <div>
            <div>
              <label>
                <span
                  style={{
                    display: 'inline-block',
                    width: '70px',
                    textAlign: 'right',
                    paddingRight: '0.5em',
                    marginBottom: '1em',
                  }}
                >
                  User Name:
                </span>
                <input
                  type="text"
                  style={{ width: '27em', maxWidth: '70%' }}
                  autoFocus={true}
                  placeholder={userName}
                  onChange={handleChange}
                  name="userName"
                />
              </label>
            </div>
            <div>
              <label>
                <span
                  style={{
                    display: 'inline-block',
                    width: '70px',
                    textAlign: 'right',
                    paddingRight: '0.5em',
                  }}
                >
                  Password:
                </span>
                <input
                  type="text"
                  style={{ width: '27em', maxWidth: '70%' }}
                  placeholder={password}
                  onChange={handleChange}
                  name="password"
                />
              </label>
            </div>
          </div>
          <DialogActionsBar>
            <button
              onClick={updateProfile}
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
            >
              Edit
            </button>
            <button
              onClick={handleClose}
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
            >
              Cancel
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
    </div>
  );
};
